<template>
    <v-container class="w-container-edit-controllo v-overflow edit_controllo_agila" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemCompany" class="clDDL">

                            <ion-label position="floating" class="clDDLLabel">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text=""
                                :value="controllo.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{
                                    item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>

                        <div id="msgErrCompany" class="clMsgError"></div>

                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_tipo_progetto_id" style="cursor:pointer">
                        <ion-item id="itemTipoProgetto" class="clDDL">

                            <ion-label class="clDDLLabel" position="floating">Tipo Progetto </ion-label>

                            <ion-select id="tipo_progetto_id" name="tipo_progetto_id" class='tipo_progetto_id'
                                cancel-Text="Chiudi" done-Text="" :value="controllo.tipo_progetto_id"
                                interface="action-sheet">

                                <ion-select-option v-for="item in tipo_progetto" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>

                        <div id="msgErrTipoProg" class="clMsgError"></div>

                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_praticaid" style="cursor:pointer">
                        <ion-item id="itemPratica" class="clDDL">

                            <ion-label class="clDDLLabel" position="floating">Pratica </ion-label>

                            <ion-select id="praticaid" name="praticaid" class='praticaid' cancel-Text="Chiudi" done-Text=""
                                :value="controllo.praticaid" interface="action-sheet">

                                <ion-select-option v-for="item in pratiche" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>

                        <div id="msgErrPratica" class="clMsgError"></div>

                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="nome" v-model="controllo.nome" label="Nome" required
                        :rules="nameRules"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="descrizione" v-model="controllo.descrizione" label="Descrizione"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_idresources_group_name" style="cursor:pointer">
                        <ion-item id="itemGruppo"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Gruppo </ion-label>

                            <ion-select id="idresources_group_name" name="idresources_group_name"
                                class='idresources_group_name' cancel-Text="Chiudi" done-Text=""
                                :value="controllo.idresources_group_name" interface="action-sheet">

                                <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name"
                                    :value='item.idresources_group_name'> {{
                                        item.name
                                    }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_status" style="cursor:pointer">
                        <ion-item id="itemStatus" class="clDDL">

                            <ion-label class="clDDLLabel"
                                position="floating">Stato </ion-label>

                            <ion-select id="status" name="status" class='status' cancel-Text="Chiudi" done-Text=""
                                :value="controllo.status" interface="action-sheet">

                                <ion-select-option v-for="item in stati" :key="item.id" :value='item.id'> {{
                                    item.name
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>

                        <div id="msgErrStato" class="clMsgError"></div>

                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_tipo_controllo" style="cursor:pointer">
                        <ion-item id="itemTipoControllo" class="clDDL">

                            <ion-label class="clDDLLabel" position="floating">Tipo </ion-label>

                            <ion-select id="id_tipo_controllo" name="id_tipo_controllo" class='id_tipo_controllo'
                                cancel-Text="Chiudi" done-Text="" :value="controllo.id_tipo_controllo"
                                interface="action-sheet">

                                <ion-select-option v-for="item in tipo_controllo" :key="item.id" :value='item.id'> {{
                                    item.descrizione
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>

                        <div id="msgErrTipo" class="clMsgError"></div>

                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="d_posizione"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot">
                                    <label for="input-29"
                                        class="v-label v-label--active theme--light clNumLabel">Posizione</label>

                                    <vue-autonumeric v-model="controllo.posizione" :disabled="true"
                                        :options="'integer'"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div id="msgErrPosizione" class="clNumMsgError"></div>

                </v-col>

            </v-row>


            <v-row>
                <div style="display:flex;width:100%;margin-left:24px;color:black;font-size:11px;font-weight:bold;">
                    Tipo Comunicazione
                </div>
            </v-row>


            <v-row>
                <div style="margin-left:25px;margin-top:12px">
                    <img src="@/assets/btn_rimuovi.png" style="max-width:18px;max-height:18px" title="Niente" />
                </div>

                <div style="margin-left:25px;margin-top:12px">
                    <img src="@/assets/btn_mail.png" style="max-width:22px;max-height:22px;padding-top:1px"
                        title="Invia Mail" />
                </div>


                <div style="margin-left:22px;margin-top:12px" id="divTitStorico">
                    <img src="@/assets/history2.png" style="max-width:18px;max-height:18px" title="Storico" />
                </div>

            </v-row>

            <v-row>
                <div style="margin-left:11px">

                    <v-checkbox label="" v-model="removeValue" true-value="S" false-value="N" id="chkRemove"
                        class="cl-checkbox" @click="chkRemoveValue"></v-checkbox>

                </div>

                <div style="margin-left:-6px">

                    <v-checkbox label="" v-model="controllo.send_as_mail" true-value="S" false-value="N" id="chkMail"
                        class="cl-checkbox" @click="chkCancelRemoveValue"></v-checkbox>

                </div>


                <div style="margin-left:-10px" id="divValStorico">

                    <v-checkbox label="" v-model="controllo.enable_history" true-value="S" false-value="N" id="chkHistory"
                        class="cl-checkbox"></v-checkbox>

                </div>

            </v-row>



        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                        id="fldTextSearchAzi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupposizione">

            <template>
                <modal name="popupPosizione" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label
                                style="font-size:15px;margin-left:8px;color:black;font-weight:bold;font-size:11px">Posizione</label>

                            <div id="d_posizione_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePosizione" :options="'integer'"
                                                ref="fldPosizione" id="fldPosizione"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-posizione" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelPosizione" />

                                <v-img alt="" class="btn-confirm-posizione" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmPosizione" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apigroups from "../utils/groups/apigroups";
import apicontrolli from "../utils/controlli/apicontrolli";


import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        console.log("AZIENDE: ", window.ddlAziende);


        var pointerVue = this;


        this.cur_id = this.decodeString(this.$route.params.id);

        this.setupButtons();


        this.aziende = window.ddlAziende;

        this.tipo_progetto = window.ddlTipoProgetto;

        this.praticheTemp = window.ddlPratiche;

        this.gruppi = window.ddlGruppi;

        this.tipo_controllo = window.ddlTipoControllo;

        // tipo_controllo

        if (this.cur_id == 0) {

            $("#divTitStorico").hide();
            $("#divValStorico").hide();


        }

        if (this.cur_id > 0) {

            this.titolo = "Modifica Controllo";

            this.initEditGruppo();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    //  this.saveData();

                    this.validate();

                    break;


                case "btn_back":



                    var id_pratica = window.$cookies.get("id_pratica");
                    var id_tipo_prog = window.$cookies.get("id_tipo_prog");
                    var id_tipo_ctrl = window.$cookies.get("id_tipo_ctrl");
                    var id_azi = window.$cookies.get("id_azi");


                    router.push({
                        path: "/controlli/" + id_pratica + "/" + id_tipo_prog + "/" + id_tipo_ctrl + "/" + id_azi
                    });

                    break;

                case "btn_utenti":

                    window.$cookies.set("sel_filter_azienda_gruppo_utenti", $("#companyid").val(), "9y");
                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupSelUsers"
                    });


                    break;

                case "btn_mail":

                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupMails"
                    });

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#d_posizione', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPosizione');

            });

            $('body').on('ionChange', '.tipo_progetto_id', function (event) {
                console.log(event);

                pointerVue.controllo.tipo_progetto_id = this.value;

                pointerVue.setPratiche(pointerVue.controllo.companyid, pointerVue.controllo.tipo_progetto_id);

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.praticaid', function (event) {
                console.log(event);

                pointerVue.controllo.praticaid = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.id_tipo_controllo', function (event) {
                console.log(event);

                pointerVue.controllo.id_tipo_controllo = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.idresources_group_name', function (event) {
                console.log(event);

                pointerVue.controllo.idresources_group_name = this.value;

            });

            $('body').on('ionChange', '.status', function (event) {
                console.log(event);

                pointerVue.controllo.status = this.value;

            });



            $('body').on('blur', '#nome', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                    setTimeout(() => {

                        $("#fldTextSearchAzi").focus();

                    }, 500);

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({



        nameRules: [
            (v) => !!v || "Campo richiesto",
        ],



        removeValue: "S",

        valorePosizione: null,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        controllo: { companyid: "", tipo_progetto_id: "", nome: "", praticaid: "", id_tipo_controllo: "", posizione: "", send_as_mail: "N", send_as_sms: "N", send_as_wapp: "N", enable_history: "N", status: "" },

        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        tipo_progetto: [],

        pratiche: [],

        praticheTemp: [],

        gruppi: [],

        tipo_controllo: [],

        stati: [{ id: "N", name: "No" }, { id: "Y", name: "Si" }, { id: "1", name: "NA" }, { id: "2", name: "WIP" }],

        currAzienda: "",
        titolo: "Aggiungi Controllo",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],



        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    components: {
        VueAutonumeric,
    },


    methods: {


        chkCancelRemoveValue: function () {

            this.removeValue = "N";

        },


        chkRemoveValue: function () {

            if (this.removeValue == "S") {

                this.controllo.send_as_mail = "N";
                this.controllo.send_as_sms = "N";
                this.controllo.send_as_wapp = "N";

            }

        },

        btnConfirmPosizione: function () {

            this.controllo.posizione = this.valorePosizione;

            this.$modal.hide('popupPosizione');

            this.setupButtons();

        },


        btnCancelPosizione: function () {

            this.$modal.hide('popupPosizione');

        },

        decodeString: function (value) {

            var v = value.substring(0, value.length - 1);

            console.log("VAL: ", v);

            return atob(v);

        },



        enableSaveButton: function () {

            var result = 1;

            console.log("CONTROLLO: ", this.controllo);

            if (this.controllo.companyid == "") {
                result = 0;
            }

            if (result == 1) {
                if (this.controllo.tipo_progetto_id == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.praticaid == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.nome == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.id_tipo_controllo == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.posizione == "") {
                    result = 0;
                }
            }



            return result;

        },

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },


        setPratiche: function (id_azienda, id_tipo_progetto) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("TIPO PROJ 2: ", id_tipo_progetto);

            console.log("PRAT TMP: ", this.praticheTemp);

            var Pratiche = [];

            if ((id_azienda != "") && (id_tipo_progetto != "")) {

                for (var i = 0; i < this.praticheTemp.length; i++) {

                    if ((parseInt(this.praticheTemp[i].companyid) == parseInt(id_azienda)) && (parseInt(this.praticheTemp[i].tipo_progetto_id) == parseInt(id_tipo_progetto))) {

                        Pratiche.push({
                            id: this.praticheTemp[i].id,
                            nome: this.praticheTemp[i].nome
                        });

                    }

                }

            }

            console.log("PRAT SEL: ", Pratiche);

            this.pratiche = Pratiche;

        },






        setScopes: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SCOPES TMP: ", this.scopesTemp);

            var scopesAzienda = [];

            for (var i = 0; i < this.scopesTemp.length; i++) {

                if (parseInt(this.scopesTemp[i].company_id) == parseInt(id_azienda)) {

                    scopesAzienda.push({
                        id: this.scopesTemp[i].id,
                        name: this.scopesTemp[i].name
                    });

                }

            }

            console.log("SCOPES SEL: ", scopesAzienda);

            this.scopes = scopesAzienda;

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.controllo.companyid = azienda.companyid;

            this.setPratiche(this.controllo.companyid, this.controllo.tipo_progetto_id);

            this.setupButtons();
        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                // var enableSave = this.enableSaveButton();
                var enableSave = 1;

                if (enableSave == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30
                    });



                }



                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },

        validate() {

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Verifica i dati",
                    showConfirmButton: false,
                    timer: 2000
                });

            } else {

                var errore = 0;

                $("#itemCompany").removeClass("clDDLError");
                $("#itemCompany > ion-label").removeClass("clDDLLabelError");
                $("#itemCompany").addClass("clDDL");
                $("#itemCompany > ion-label").addClass("clDDLLabel");
                $("#msgErrCompany").html("&nbsp;");

                if (this.controllo.companyid == "") {

                    $("#itemCompany").removeClass("clDDL");
                    $("#itemCompany > ion-label").removeClass("clDDLLabel");
                    $("#itemCompany").addClass("clDDLError");
                    $("#itemCompany > ion-label").addClass("clDDLLabelError");
                    $("#msgErrCompany").html("Campo richiesto");

                    errore = 1;

                }


                $("#itemTipoProgetto").removeClass("clDDLError");
                $("#itemTipoProgetto > ion-label").removeClass("clDDLLabelError");
                $("#itemTipoProgetto").addClass("clDDL");
                $("#itemTipoProgetto > ion-label").addClass("clDDLLabel");
                $("#msgErrTipoProg").html("&nbsp;");

                if (this.controllo.tipo_progetto_id == "") {

                    $("#itemTipoProgetto").removeClass("clDDL");
                    $("#itemTipoProgetto > ion-label").removeClass("clDDLLabel");
                    $("#itemTipoProgetto").addClass("clDDLError");
                    $("#itemTipoProgetto > ion-label").addClass("clDDLLabelError");
                    $("#msgErrTipoProg").html("Campo richiesto");

                    errore = 1;

                }


                $("#itemPratica").removeClass("clDDLError");
                $("#itemPratica > ion-label").removeClass("clDDLLabelError");
                $("#itemPratica").addClass("clDDL");
                $("#itemPratica > ion-label").addClass("clDDLLabel");
                $("#msgErrPratica").html("&nbsp;");

                if (this.controllo.praticaid == "") {

                    $("#itemPratica").removeClass("clDDL");
                    $("#itemPratica > ion-label").removeClass("clDDLLabel");
                    $("#itemPratica").addClass("clDDLError");
                    $("#itemPratica > ion-label").addClass("clDDLLabelError");
                    $("#msgErrPratica").html("Campo richiesto");

                    errore = 1;

                }


                $("#itemTipoControllo").removeClass("clDDLError");
                $("#itemTipoControllo > ion-label").removeClass("clDDLLabelError");
                $("#itemTipoControllo").addClass("clDDL");
                $("#itemTipoControllo > ion-label").addClass("clDDLLabel");
                $("#msgErrTipo").html("&nbsp;");

                if (this.controllo.id_tipo_controllo == "") {

                    $("#itemTipoControllo").removeClass("clDDL");
                    $("#itemTipoControllo > ion-label").removeClass("clDDLLabel");
                    $("#itemTipoControllo").addClass("clDDLError");
                    $("#itemTipoControllo > ion-label").addClass("clDDLLabelError");
                    $("#msgErrTipo").html("Campo richiesto");

                    errore = 1;

                }




                $("#itemStatus").removeClass("clDDLError");
                $("#itemStatus > ion-label").removeClass("clDDLLabelError");
                $("#itemStatus").addClass("clDDL");
                $("#itemStatus > ion-label").addClass("clDDLLabel");
                $("#msgErrStato").html("&nbsp;");

                if (this.controllo.status == "") {

                    $("#itemStatus").removeClass("clDDL");
                    $("#itemStatus > ion-label").removeClass("clDDLLabel");
                    $("#itemStatus").addClass("clDDLError");
                    $("#itemStatus > ion-label").addClass("clDDLLabelError");
                    $("#msgErrStato").html("Campo richiesto");

                    errore = 1;

                }





                $("#msgErrPosizione").html("&nbsp;");

                if (this.controllo.posizione == "") {


                    $("#msgErrPosizione").html("Campo richiesto");

                    errore = 1;

                }


                if (errore == 0) {

                    console.log("ok");

                    this.saveData();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Verifica i dati",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }




            }
        },

        validateOld() {


            //var that = this;

            var errore = "";

            if (this.controllo.companyid == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {
                if (this.controllo.tipo_progetto_id == "") {
                    errore = "Specificare Tipo Progetto";
                }
            }

            if (result == 1) {
                if (this.controllo.praticaid == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.nome == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.id_tipo_controllo == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.posizione == "") {
                    result = 0;
                }
            }



            // var that = this;

            // var errore = "";

            // if ($("#companyid").val() == "") {
            //     errore = "Specificare l'azienda";
            // }

            // if (errore == "") {

            //     if ($("#name").val() == "") {
            //         errore = "Specificare il nome";
            //     }

            // }

            // if (errore == "") {

            //     if ($("#departmentid").val() == "") {
            //         errore = "Specificare il dipartimento";
            //     }

            // }


            // if ($("#group_scope_id").val() != "") {

            //     that.gruppo.group_scope_id = $("#group_scope_id").val();

            // }


            // if (errore == "") {


            //     that.saveData();

            // }

            // if (errore != "") {

            //     that.$swal({
            //         icon: "error",
            //         text: errore,
            //         showConfirmButton: false,
            //         timer: 3000
            //     });


            // }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                this.scopesTemp = res.data.scope;


                // imposto di default l'azienda del filtro
                var v_company = window.$cookies.get("sel_filter_azienda_gruppi");

                that.gruppo.companyid = v_company;

                this.setDipartimenti(v_company);

                this.setScopes(v_company);


                $("#itemCompany").addClass("item-has-value");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        initEditGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicontrolli.editControllo(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editControllo", res);

                that.setPratiche(res.data.controllo.companyid, res.data.controllo.tipo_progetto_id);

                that.controllo = res.data.controllo;

                if (res.data.controllo.companyid != null) {

                    $("#itemAzienda").addClass("item-has-value");

                }

                if (res.data.controllo.tipo_progetto_id != null) {

                    $("#itemTipoProgetto").addClass("item-has-value");

                }

                if (res.data.controllo.praticaid != null) {

                    $("#itemPratica").addClass("item-has-value");

                }

                if (res.data.controllo.idresources_group_name != null) {

                    $("#itemGruppo").addClass("item-has-value");

                }

                if (res.data.controllo.status != null) {

                    $("#itemStatus").addClass("item-has-value");

                }


                if (res.data.controllo.send_as_mail != "N") {

                    that.removeValue = "N";

                }


                that.setupButtons();



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {


            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("CONTROLLO: ", that.controllo);
            console.log("CUR ID: ", that.cur_id);
            console.log("TOKEN: ", v_token);


            if (that.cur_id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apicontrolli.insertControllo(
                    that.controllo,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertControllo", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();



                        var id_pratica = window.$cookies.get("id_pratica");
                        var id_tipo_prog = window.$cookies.get("id_tipo_prog");
                        var id_tipo_ctrl = window.$cookies.get("id_tipo_ctrl");
                        var id_azi = window.$cookies.get("id_azi");


                        router.push({
                            path: "/controlli/" + id_pratica + "/" + id_tipo_prog + "/" + id_tipo_ctrl + "/" + id_azi
                        });



                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }


            if (that.cur_id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apicontrolli.updateControllo(
                    that.controllo,
                    v_token,
                    that.$route.params.id
                ).then((res2) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateControllo", res2);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();



                        // var id_pratica = window.$cookies.get("id_pratica");


                        // router.push({
                        //     path: "/controlli/" + id_pratica
                        // });


                        var id_pratica = window.$cookies.get("id_pratica");
                        var id_tipo_prog = window.$cookies.get("id_tipo_prog");
                        var id_tipo_ctrl = window.$cookies.get("id_tipo_ctrl");
                        var id_azi = window.$cookies.get("id_azi");


                        router.push({
                            path: "/controlli/" + id_pratica + "/" + id_tipo_prog + "/" + id_tipo_ctrl + "/" + id_azi
                        });

                    }, 200);

                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );


            }





            // this.cur_id 

            // var id_rec = that.$route.params.id;

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apisedioperative.saveSedeOperativa(
            //     that.sede_operativa,
            //     v_token,
            //     id_rec
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from saveSedeOperativa", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     setTimeout(() => {

            //         that.dialogMsg = false;
            //         that.setupButtons();

            //         router.push({
            //             path: "/sediOperative"
            //         });

            //     }, 200);

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("Errori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.clNumLabel {
    left: 0px !important;
    right: auto !important;
    position: absolute !important;
}

.clNumLabelError {
    left: 0px !important;
    right: auto !important;
    position: absolute !important;
    color: red !important;
}


.clMsgError {
    color: red;
    margin-top: -11px;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
}

.clNumMsgError {
    color: red;
    margin-top: -26px;
    margin-left: 9px;
    font-size: 12px;
    font-weight: 600;
}

.clDDL {
    min-width: 190px !important;
    transform: translateX(-6px) !important;
    margin-top: -24px !important;
    margin-bottom: 18px !important
}

.clDDLLabel {
    font-size: 11px !important;
    color: black !important;
    cursor: pointer !important;
    font-weight: bold !important;
    opacity: 1 !important;
}


.clDDLError {
    min-width: 190px !important;
    transform: translateX(-6px) !important;
    margin-top: -24px !important;
    margin-bottom: 18px !important;
    border-bottom: 1px solid red !important;
}

.clDDLLabelError {
    font-size: 11px !important;
    color: red !important;
    cursor: pointer !important;
    font-weight: bold !important;
    opacity: 1 !important;
}








.w-container-edit-controllo .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.w-container-edit-controllo {
    width: 500px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-controllo {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.edit_controllo_agila .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_controllo_agila .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_controllo_agila ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}



.edit_controllo_agila .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.edit_controllo_agila .v-input.cl-checkbox.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
    margin-top: 0px !important;
}

.edit_controllo_agila .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0px !important;
}
</style>
