<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">
        <v-row>



            <v-col v-if="viewLogSMS == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/logSMS">
                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/log_sms_sendmagic.png"
                                transition="scale-transition" />
                        </v-btn>
                        <div class="btnText">Log SMS</div>
                    </template>
                    <span>Log SMS</span>
                </v-tooltip>

            </v-col>

            <v-col v-if="viewLogNotification == 1" cols="6" class="mb-15">

                <v-tooltip top>

                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/logNotification">
                            <v-img alt="Emt logo" width="60" class=" " contain
                                src="@/assets/log_notification_sendmagic.png" transition="scale-transition" />
                        </v-btn>
                        <div class="btnText">Log Notification</div>
                    </template>
                    <span>Log Notification</span>
                </v-tooltip>

            </v-col>


            <v-col cols="6" class="mb-15">

            </v-col>

            <v-col cols="6" class="mb-15">

            </v-col>




        </v-row>
    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";
import apisendmagic from "../utils/sendmagic/apisendmagic";
import apinotification from "../utils/notification/apinotification";


export default {
    name: "HelloWorld",

    mounted: function () {

        this.$root.$children[0].checkLogout();

        this.checkChangePwd();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        this.isCustSupp = window.$cookies.get("is_cust_supp");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        if (this.isSU == 0) {


            this.viewLogNotification = this.getEnableConf("log_notif_sm");
            this.viewLogSMS = this.getEnableConf("log_sms_sm");
            this.viewLogWhatsApp = this.getEnableConf("log_whatsapp_sm");
            this.viewSendNotification = this.getEnableConf("send_notif_sm");
            this.viewSendSMS = this.getEnableConf("send_sms_sm");
            this.viewProfilo = this.getEnableConf("profilo_sm");
            this.viewSendWhatsApp = this.getEnableConf("send_whatsapp_sm");


        }
        else {

            this.viewLogNotification = 1;
            this.viewLogSMS = 1;
            this.viewLogWhatsApp = 1;
            this.viewSendNotification = 1;
            this.viewSendSMS = 1;
            this.viewProfilo = 1;
            this.viewSendWhatsApp = 1;

        }



        this.loadLogo();

        this.getLastLogin();

        //this.checkRegNotification();

        this.checkRegNotificationTest();

    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },

    methods: {

        saveRegistration: async function (product, endpoint, publickey, token) {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apinotification.saveRegistration(
                v_token,
                product,
                endpoint,
                publickey,
                token,
                this.getBrowser()
            ).then((res) => {

                console.log("res from saveRegistration", res);

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                if (res.data.Result == "OK") {




                    that.$swal({
                        icon: "success",
                        text: "Notifiche abilitate con successo",
                        showConfirmButton: false,
                        timer: 3000
                    });

                    setTimeout(() => {

                        var pulsantiNotif2 = [];

                        pulsantiNotif2.push({
                            text: "",
                            icon: "mdi-account",
                            link: "",
                            id: "btnEnableNotification",
                            disabled: false,
                        });


                        that.$root.$children[0].buttonsEnableNotification = pulsantiNotif2;

                        that.$root.$children[0].buttonsDisableNotification = [];



                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }


            }).catch(err => {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        subscribeUserToPush: function (registration) {

            var that = this;


            const applicationServerKey = this.urlB64ToUint8Array('BOSsMJaAgY7z9C8MsxRqf-wa7JTx_rE7E0o6i9snxoCjGASxBh_jr-0NU34tkJS1OoMzdDZzDsXIClNLekQ9oJ0');
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            })
                .then(function (subscription) {
                    console.log('User is subscribed:', subscription);

                    alert('USER IS SUBSCRIBE');

                    that.saveRegistration("sendmagic",
                        subscription.endpoint,
                        that.arrayBufferToBase64(subscription.getKey('p256dh')),
                        that.arrayBufferToBase64(subscription.getKey('auth')));



                })
                .catch(function (error) {


                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;


                    console.error('Failed to subscribe the user:', error);
                });


        },


        registerUser: function () {


            var that = this;

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register(that.getUrlScript())
                    .then(function (registration) {
                        console.log('Service Worker registered with scope:', registration.scope);

                        alert("SERVICE WORKER REGISTERED");

                        Notification.requestPermission().then(function (permission) {
                            if (permission === 'granted') {
                                that.subscribeUserToPush(registration);
                            }
                            else {


                                that.showSpinner = false;
                                that.$root.$children[0].showProgress = false;

                                that.$swal({
                                    icon: "error",
                                    text: "Notifiche non permesse",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                            }
                        });
                    });
            }
            else {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

            }


        },


        checkRegistration: async function (product, endpoint, publickey, token) {

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apinotification.checkRegistration(
                v_token,
                product,
                endpoint,
                publickey,
                token,
                this.getBrowser()
            ).then((res) => {

                console.log("res from checkRegistration", res);


                if (res.data.Result == "OK") {

                    if (res.data.IsExist == 0) {

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;


                        that.$swal
                            .fire({
                                title: "Notifiche",
                                html: "Vuoi abilitare la ricezione delle notifiche ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_sendmagic.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_sendmagic.png' />",
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }

                            })
                            .then(async function (result) {

                                if (result.value == true) {

                                    console.log("ok");

                                    that.showSpinner = true;
                                    that.$root.$children[0].showProgress = true;

                                    that.registerUser();


                                }
                                else {

                                    var pulsantiNotif = [];

                                    pulsantiNotif.push({
                                        text: "",
                                        icon: "mdi-account",
                                        link: "",
                                        id: "btnDisableNotification",
                                        disabled: false,
                                    });


                                    that.$root.$children[0].buttonsDisableNotification = pulsantiNotif


                                }

                            });


                    }
                    else {

                        var pulsantiNotif3 = [];

                        pulsantiNotif3.push({
                            text: "",
                            icon: "mdi-account",
                            link: "",
                            id: "btnEnableNotification",
                            disabled: false,
                        });


                        that.$root.$children[0].buttonsEnableNotification = pulsantiNotif3
                    }

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        arrayBufferToBase64: function (buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },

        urlB64ToUint8Array: function (base64String) {

            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;



        },


        subscribeUserForCheck: function (registration) {

            var that = this;

            const applicationServerKey = this.urlB64ToUint8Array('BOSsMJaAgY7z9C8MsxRqf-wa7JTx_rE7E0o6i9snxoCjGASxBh_jr-0NU34tkJS1OoMzdDZzDsXIClNLekQ9oJ0');
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            })
                .then(function (subscription) {
                    console.log('User is subscribed:', subscription);

                    alert("USER IS SUBSCRIBE");


                    setTimeout(() => {

                        that.checkRegistration("sendmagic",
                            subscription.endpoint,
                            that.arrayBufferToBase64(subscription.getKey('p256dh')),
                            that.arrayBufferToBase64(subscription.getKey('auth')));

                    }, 200);




                })
                .catch(function (error) {
                    console.error('Failed to subscribe the user:', error);
                });


        },



        getUrlScript: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = "/scripts/service-worker.js";
            }

            if (location.hostname != "localhost") {

                result = "/sendmagic/scripts/service-worker.js";

            }

            return result;

        },

        registerUserForCheck: function () {


            var that = this;

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register(that.getUrlScript())
                    .then(function (registration) {
                        console.log('Service Worker registered with scope:', registration.scope);

                        //alert("Service Worker registered with scope: " + registration.scope);

                        Notification.requestPermission().then(function (permission) {
                            if (permission === 'granted') {


                                setTimeout(() => {

                                    that.subscribeUserForCheck(registration);

                                }, 200);



                            }
                        });
                    });
            }


        },

        initRegistrationForCheck: async function () {

            var that = this;


            var response = await apinotification.initRegistration(
                0
            ).then((res) => {

                console.log("res from initRegistration", res);

                window.keyCode = atob(res.data.Result);

                that.registerUserForCheck();


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getBrowser: function () {

            var result = "";

            if (navigator.userAgent.indexOf('Chrome') !== -1) {
                result = "Chrome";
            }
            else {

                if (navigator.userAgent.indexOf('Safari') !== -1) {
                    result = "Safari";
                }
                else {
                    result = "Firefox";
                }

            }

            return result;
        },


        checkRegNotificationTest: function () {

            var that = this;

            var checkRegNotification = window.$cookies.get("checkRegNotification");


            if (checkRegNotification == 1) {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                if ('serviceWorker' in navigator) {

                    Notification.requestPermission().then(function (permission) {

                        if (permission === 'granted') {

                            alert("PERMISSION GRANTED");

                            alert("SCRIPT: " + that.getUrlScript());


                            navigator.serviceWorker.register(that.getUrlScript())
                                .then(function (registration) {

                                    alert("REGISTRATION OK");


                                    setTimeout(() => {


                                        registration.pushManager.getSubscription().then(function (subscription) {

                                            console.log("subscription: ", subscription);

                                            alert("GET SUBSCRIPTION OK");

                                            if (subscription) {

                                                // devo verificare se la registrazione è presente nel db
                                                setTimeout(() => {

                                                    that.checkRegistration("sendmagic",
                                                        subscription.endpoint,
                                                        that.arrayBufferToBase64(subscription.getKey('p256dh')),
                                                        that.arrayBufferToBase64(subscription.getKey('auth')));

                                                }, 200);



                                            }
                                            else {


                                                that.showSpinner = false;
                                                that.$root.$children[0].showProgress = false;

                                                that.$swal
                                                    .fire({
                                                        title: "Notifiche",
                                                        html: "Vuoi abilitare la ricezione delle notifiche ?",
                                                        icon: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_sendmagic.png' />",
                                                        cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_sendmagic.png' />",
                                                        customClass: {
                                                            cancelButton: 'order-1',
                                                            confirmButton: 'order-2',
                                                        }

                                                    })
                                                    .then(async function (result) {

                                                        if (result.value == true) {

                                                            console.log("ok");

                                                            that.showSpinner = true;
                                                            that.$root.$children[0].showProgress = true;


                                                            that.subscribeUserToPush(registration);


                                                        }
                                                        else {

                                                            var pulsantiNotif = [];

                                                            pulsantiNotif.push({
                                                                text: "",
                                                                icon: "mdi-account",
                                                                link: "",
                                                                id: "btnDisableNotification",
                                                                disabled: false,
                                                            });


                                                            that.$root.$children[0].buttonsDisableNotification = pulsantiNotif


                                                        }

                                                    });





                                            }





                                        });





                                    }, 200);




                                });



                        }
                        else {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;

                            alert("PERMISSION DANIED");

                        }



                    });



                }





            }






        },






        checkRegNotification: function () {

            var that = this;


            console.log("BROWSER: ", this.getBrowser());


            var checkRegNotification = window.$cookies.get("checkRegNotification");

            if (checkRegNotification == 1) {

                window.$cookies.set("checkRegNotification", 0, "9y");

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                that.doCheckRegNotification();


            }


        },





        // checkRegNotification: async function () {

        //     var that = this;


        //     console.log("BROWSER: ", this.getBrowser());


        //     var checkRegNotification = window.$cookies.get("checkRegNotification");

        //     if (checkRegNotification == 1) {

        //         window.$cookies.set("checkRegNotification", 0, "9y");

        //         that.showSpinner = true;
        //         that.$root.$children[0].showProgress = true;

        //         that.doCheckRegNotification();


        //     }


        // },



        doCheckRegNotification: function () {

            var that = this;

            if ('serviceWorker' in navigator) {


                alert("SCRIPT: " + that.getUrlScript());

                navigator.serviceWorker.register(that.getUrlScript())
                    .then(function (registration) {


                        alert("REGISTRATION OK");

                        Notification.requestPermission().then(function (permission) {

                            if (permission === 'granted') {

                                alert("PERMISSION GRANTED");

                                setTimeout(() => {


                                    registration.pushManager.getSubscription().then(function (subscription) {

                                        console.log("subscription: ", subscription);

                                        alert("GET SUBSCRIPTION OK");

                                        if (subscription) {

                                            // devo verificare se la registrazione è presente nel db
                                            setTimeout(() => {

                                                that.checkRegistration("sendmagic",
                                                    subscription.endpoint,
                                                    that.arrayBufferToBase64(subscription.getKey('p256dh')),
                                                    that.arrayBufferToBase64(subscription.getKey('auth')));

                                            }, 200);



                                        }
                                        else {


                                            that.showSpinner = false;
                                            that.$root.$children[0].showProgress = false;

                                            that.$swal
                                                .fire({
                                                    title: "Notifiche",
                                                    html: "Vuoi abilitare la ricezione delle notifiche ?",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_sendmagic.png' />",
                                                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_sendmagic.png' />",
                                                    customClass: {
                                                        cancelButton: 'order-1',
                                                        confirmButton: 'order-2',
                                                    }

                                                })
                                                .then(async function (result) {

                                                    if (result.value == true) {

                                                        console.log("ok");

                                                        that.showSpinner = true;
                                                        that.$root.$children[0].showProgress = true;


                                                        that.subscribeUserToPush(registration);


                                                    }
                                                    else {

                                                        var pulsantiNotif = [];

                                                        pulsantiNotif.push({
                                                            text: "",
                                                            icon: "mdi-account",
                                                            link: "",
                                                            id: "btnDisableNotification",
                                                            disabled: false,
                                                        });


                                                        that.$root.$children[0].buttonsDisableNotification = pulsantiNotif


                                                    }

                                                });





                                        }





                                    });





                                }, 200);




                            }
                            else {

                                that.showSpinner = false;
                                that.$root.$children[0].showProgress = false;


                            }






                        });




                    });



            }
            else {

                alert("serviceWorker not in navigator");

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

            }


        },







        // doCheckRegNotification: async function () {

        //     var that = this;

        //     if ('serviceWorker' in navigator) {


        //         alert("SCRIPT: " + that.getUrlScript());

        //         navigator.serviceWorker.register(that.getUrlScript())
        //             .then(function (registration) {


        //                 alert("REGISTRATION OK");

        //                 Notification.requestPermission().then(function (permission) {

        //                     if (permission === 'granted') {

        //                         alert("PERMISSION GRANTED");

        //                         setTimeout(() => {


        //                             registration.pushManager.getSubscription().then(function (subscription) {

        //                                 console.log("subscription: ", subscription);

        //                                 alert("GET SUBSCRIPTION OK");

        //                                 if (subscription) {

        //                                     // devo verificare se la registrazione è presente nel db
        //                                     setTimeout(() => {

        //                                         that.checkRegistration("sendmagic",
        //                                             subscription.endpoint,
        //                                             that.arrayBufferToBase64(subscription.getKey('p256dh')),
        //                                             that.arrayBufferToBase64(subscription.getKey('auth')));

        //                                     }, 200);



        //                                 }
        //                                 else {


        //                                     that.showSpinner = false;
        //                                     that.$root.$children[0].showProgress = false;

        //                                     that.$swal
        //                                         .fire({
        //                                             title: "Notifiche",
        //                                             html: "Vuoi abilitare la ricezione delle notifiche ?",
        //                                             icon: "warning",
        //                                             showCancelButton: true,
        //                                             confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_sendmagic.png' />",
        //                                             cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_sendmagic.png' />",
        //                                             customClass: {
        //                                                 cancelButton: 'order-1',
        //                                                 confirmButton: 'order-2',
        //                                             }

        //                                         })
        //                                         .then(async function (result) {

        //                                             if (result.value == true) {

        //                                                 console.log("ok");

        //                                                 that.showSpinner = true;
        //                                                 that.$root.$children[0].showProgress = true;


        //                                                 that.subscribeUserToPush(registration);


        //                                             }
        //                                             else {

        //                                                 var pulsantiNotif = [];

        //                                                 pulsantiNotif.push({
        //                                                     text: "",
        //                                                     icon: "mdi-account",
        //                                                     link: "",
        //                                                     id: "btnDisableNotification",
        //                                                     disabled: false,
        //                                                 });


        //                                                 that.$root.$children[0].buttonsDisableNotification = pulsantiNotif


        //                                             }

        //                                         });





        //                                 }





        //                             });





        //                         }, 200);




        //                     }
        //                     else {

        //                         that.showSpinner = false;
        //                         that.$root.$children[0].showProgress = false;


        //                     }






        //                 });




        //             });



        //     }
        //     else {

        //         alert("serviceWorker not in navigator");

        //         that.showSpinner = false;
        //         that.$root.$children[0].showProgress = false;

        //     }


        // },




        createTokenNotification: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Notification",
                    html: "Do you want to enable notifications on your phone ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_sendmagic.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_sendmagic.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiusers.createTokenNotification(
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from createTokenNotification", res);

                            that.$swal({
                                icon: "success",
                                text: "Notifications enabled",
                                showConfirmButton: false,
                                timer: 2000
                            });



                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );





                    }

                });


        },


        checkTokenNotification: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.checkTokenNotification(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkTokenNotification", res);

                if (res.data.TokenPresent == 0) {

                    that.createTokenNotification();

                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },




        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            if (v_product_permissions != null) {


                var elements = v_product_permissions.split("|");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if (elements[x] == v_codice) {

                        result = 1;

                    }


                }

            }



            return result;

        },


        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "sendmagic"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        checkChangePwd: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkChangePwd(
                atob(v_token)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkChangePwd", res);

                if (res.data.IsChanged == 0) {

                    that.$root.$children[0].logout();


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "2recruit")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },



    },


    data: () => ({

        viewLogNotification: 0,
        viewLogSMS: 0,
        viewLogWhatsApp: 0,
        viewSendNotification: 0,
        viewSendSMS: 0,
        viewProfilo: 0,
        viewSendWhatsApp: 0,



        isAdmin: 0,
        isSU: 0,
        isCustSupp: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.swal2-icon.swal2-warning {
    border-color: #005da8 !important;
    color: #005da8 !important;
}


.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
