<template>
    <v-container class="w-container-edit-company-sender editCompanySender"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">


        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;">


                <div class="mainTitle" style="
                background-color: white!important;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;                
                min-width:100vw;
                border-color:#005da8;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemAzienda"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Company </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="sendmagic_company_sender.companyid" interface="action-sheet"
                                disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{
                    item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_senderid" style="cursor:pointer">
                        <ion-item id="itemSender"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Sender </ion-label>

                            <ion-select id="idsender" name="idsender" class='idsender' cancel-Text="Chiudi" done-Text=""
                                :value="sendmagic_company_sender.sender" interface="action-sheet">

                                <ion-select-option v-for="item in senders" :key="item.nome" :value='item.nome'> {{
                    item.nome }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Company
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_sendmagic.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                    id="fldTextSearchAzi"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-sendmagic.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>





    </v-container>
</template>

<script>
import apisendmagic from "../utils/sendmagic/apisendmagic";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;



        this.setupButtons();

        if (this.$route.params.id == 0) {

            setTimeout(() => {

                this.initInsertCompanySender();

            }, 100);

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Edit Company Sender";

            setTimeout(() => {

                this.initEditCompanySender();

            }, 100);

        }



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/companySender"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });

            $('body').on('ionChange', '.idsender', function (event) {
                console.log(event);

                pointerVue.sendmagic_company_sender.sender = this.value;

            });



        });



    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },


        filteredClienti() {

            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        senders: [],

        prefissi: [{ value: "+39", description: "+39" }],


        viewRowPhoneNumber: false,

        viewRowTextMessage: false,

        viewRowUsersInGroup: false,

        viewRowGroup: false,

        gruppiTemp: [],
        usersTemp: [],



        viewRowCompany: false,

        typeSend: [{ id: "B", description: "Basic" }, { id: "G", description: "Group" }],


        selTypeSend: "",


        showRow: true,
        showRow2: true,

        mail_gruppo: {},

        titoloPopup: "",

        valorePesoNetto: null,
        valorePesoLordo: null,

        valoreTrasporto: null,

        totaleDettaglio: null,


        showPicker1: false,
        showPicker3: false,

        viewBanca: false,
        viewIban: false,
        viewTipoPag: false,
        viewFattRif: false,

        viewSecOrdine: false,

        valIban: "",

        codiceOrdine: "",
        dataInizioOrdine: "",
        dataFineOrdine: "",
        importoTotaleOrdine: "",
        fatturatoOrdine: "",
        risorseOrdine: "",

        fidoAnt: null,
        fidoCassa: null,

        sheetGruppi: false,

        searchGruppi: "",

        sendmagic_company_sender: {
            companyid: "",
            sender: "",
        },

        fattura_dettaglio: {},


        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Add Company Sender",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],

        clienti: [],


        dipendenti: [],
        currAzienda: "",

        iva: [],
        ivaTemp: [],

        gruppi: [],

        tipologie: [],

        banche: [],
        bancheTemp: [],

        tipiPagamento: [],
        tipiPagamentoTemp: [],

        fattureRiferimento: [],
        fattureRiferimentoTemp: [],

        ordini: [],
        ordiniTemp: [],

        trasporto: [],
        trasportoTemp: [],

        tipiContenitore: [],


        unitaDiMisura: [],
        unitaDiMisuraTemp: [],

        valute: [],
        valuteTemp: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",
        searchClienti: "",
        sheetClienti: false,

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,



        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),



    methods: {

        checkPhoneNumber: function (v_number) {

            var result;

            var validRegex = /^\d+$/;

            if (v_number.toString().match(validRegex)) {
                result = true;
            }
            else {
                result = false;

            }

            return result;

        },


        setUtenti: function (id_gruppo) {

            console.log("AZI SEL 2: ", id_gruppo);

            console.log("DIP TMP: ", this.usersTemp);

            var UtentiNelGruppo = "";

            for (var i = 0; i < this.usersTemp.length; i++) {

                if (parseInt(this.usersTemp[i].id_group) == parseInt(id_gruppo)) {

                    if (UtentiNelGruppo != "") {
                        UtentiNelGruppo = UtentiNelGruppo + "<br/>";
                    }
                    UtentiNelGruppo = UtentiNelGruppo + this.usersTemp[i].full_name;

                }

            }

            this.utentiNelGruppo = UtentiNelGruppo;

            $("#divUsersInGroup").html(UtentiNelGruppo);

            this.sendmagic_impostazioni_sms.users = UtentiNelGruppo;

        },

        setGruppi: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.gruppiTemp);

            var Gruppi = [];

            for (var i = 0; i < this.gruppiTemp.length; i++) {

                if (parseInt(this.gruppiTemp[i].companyid) == parseInt(id_azienda)) {

                    Gruppi.push({
                        id: this.gruppiTemp[i].id,
                        nome: this.gruppiTemp[i].nome,
                        companyid: this.gruppiTemp[i].companyid
                    });

                }

            }

            console.log("DIP SEL: ", Gruppi);

            this.gruppi = Gruppi;

        },

        initInsertCompanySender: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.initInsertCompanySender(
                v_token,
                "sendmagic"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertCompanySender", res);

                that.aziende = res.data.aziende;

                that.senders = res.data.senders;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        initEditCompanySender: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.initEditCompanySender(
                v_token,
                "sendmagic",
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEditCompanySender", res);

                that.aziende = res.data.aziende;

                that.senders = res.data.senders;

                that.sendmagic_company_sender = res.data.company_sender;

                $("#itemAzienda").addClass("item-has-value");

                $("#itemSender").addClass("item-has-value");

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        hideShowConfButtonDetail: function () {

            console.log("DETTAGLIO FATTURA: ", this.fattura_dettaglio);

            var enable = 1;

            if (this.fattura_dettaglio.descrizione == "") {
                enable = 0;
            }

            if (enable == 1) {

                if (this.fattura_dettaglio.idinvoice_unita_di_misura == "0") {

                    enable = 0;

                }

            }

            if (enable == 1) {

                if (this.fattura_dettaglio.quantita == "0") {

                    enable = 0;

                }

            }

            if (enable == 1) {

                if (this.fattura_dettaglio.prezzo_unitario == "0") {

                    enable = 0;

                }

            }

            if (enable == 1) {

                if (this.fattura_dettaglio.idinvoice_valuta == "0") {

                    enable = 0;

                }

            }



            return enable;

        },





        hideShowButtonsEditRowDetails: function (v_show) {

            if (v_show) {

                var v_show_conf = this.hideShowConfButtonDetail();

                console.log(v_show_conf);

                var pulsantis = [];

                if (v_show_conf == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30
                    });

                }



                pulsantis.push({

                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });

                this.$root.$children[0].bnavbuttons = pulsantis;


            }

            if (!v_show) {

                this.hideShowButtonsSelRow();

            }


        },


        updateTotalsGrid: function () {

            var trasporto = this.fattura.totale_trasporto;
            var id_iva = this.fattura.idinvoice_partita_iva;

            console.log("TRASPORTO: ", trasporto);
            console.log("ID_IVA: ", id_iva);
            console.log("ELE_IVA: ", this.ivaTemp);

            if (trasporto != null) {

                $("#v_tot_trasporto").text(trasporto.toString().replace(".", ","));
                $('#v_tot_trasporto').number(true, 2, ',', '.');

            }

            var subtotale = 0;
            $(".p_subtotale").each(function () {
                subtotale = subtotale + parseFloat($(this).text().replace(".", "").replace(",", "."));
            });

            this.fattura.totale = subtotale;

            $("#v_tot_subtotale").text(subtotale.toString().replace(".", ","));
            $('#v_tot_subtotale').number(true, 2, ',', '.');


            if (trasporto == null) {
                trasporto = 0;
            }

            var totale = parseFloat(subtotale) + parseFloat(trasporto);

            this.fattura.totale_parziale = totale;

            $("#v_tot_totale").text(totale.toString().replace(".", ","));
            $('#v_tot_totale').number(true, 2, ',', '.');


            var valore_iva = 0;

            if (id_iva != null) {



                var perc_iva = 0;

                for (var x = 0; x <= this.ivaTemp.length - 1; x++) {

                    if (parseInt(this.ivaTemp[x].idinvoice_partita_iva) == parseInt(id_iva)) {
                        perc_iva = this.ivaTemp[x].valore;
                    }

                }

                console.log("PERC IVA: ", perc_iva);

                valore_iva = (subtotale * perc_iva) / 100;

                console.log("VALORE IVA: ", valore_iva);


            }

            this.fattura.totale_iva = valore_iva;

            $("#v_tot_iva").text(valore_iva.toString().replace(".", ","));
            $('#v_tot_iva').number(true, 2, ',', '.');


            var totale_pagare = totale + valore_iva;

            this.fattura.totale_pagare = totale_pagare;

            $("#v_tot_pagare").text(totale_pagare.toString().replace(".", ","));
            $('#v_tot_pagare').number(true, 2, ',', '.');



        },


        hideShowSaveButton: function () {

            console.log("FATTURA: ", this.fattura);

            var enable = 1;

            if (this.fattura.idinvoice_tipologia == 0) {

                console.log("1");

                enable = 0;
            }

            if (enable == 1) {


                if (this.fattura.numero == "") {

                    console.log("2");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if (this.fattura.companyid == "0") {

                    console.log("3");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if (this.fattura.company == "0") {

                    console.log("4");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if ((this.fattura.data == "") || (this.fattura.data == null)) {

                    console.log("5");

                    enable = 0;

                }

            }

            if (enable == 1) {

                console.log("LEN DATA SCADENZA: ", this.fattura.data_scadenza.length);


                if ((this.fattura.data_scadenza == "") || (this.fattura.data_scadenza == null)) {

                    console.log("6");

                    enable = 0;

                }

            }

            if (enable == 1) {


                if (this.fattura.idinvoice_partita_iva == 0) {

                    console.log("7");

                    enable = 0;

                }

            }


            if (enable == 1) {

                if ((this.fattura.idinvoice_tipologia == 1) || (this.fattura.idinvoice_tipologia == 6)) {

                    if (this.fattura.idinvoice_ordini == 0) {

                        console.log("8");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.idinvoice_tipologia == 3) {

                    if (this.fattura.idinvoice_banca_no_order == 0) {

                        console.log("9");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.idinvoice_tipologia == 2) {

                    if (this.fattura.idinvoice_reference_nc == 0) {

                        console.log("10");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.idinvoice_tipologia == 3) {

                    if (this.fattura.idinvoice_tipo_pagamento_no_order == 0) {

                        console.log("11");

                        enable = 0;

                    }


                }


            }

            if (enable == 1) {

                if (this.fattura.totale > 0) {

                    if (this.fattura.idinvoice_partita_iva == 0) {

                        console.log("12");

                        enable = 0;

                    }


                }


            }

            return enable;

        },




        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;




            // var filtri = ``;



            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [


                    {
                        data: 'sel_row',
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'codice',
                        name: 'codice',
                        width: '1%',
                    },

                    {
                        data: 'descrizione',
                        name: 'descrizione',
                        width: '1%',
                    },

                    {
                        data: 'simbolo',
                        name: 'simbolo',
                        width: '1%',
                    },

                    {
                        data: 'quantita',
                        name: 'quantita',
                        width: '1%',
                    },

                    {
                        data: 'prezzo_unitario',
                        name: 'prezzo_unitario',
                        width: '1%',
                    },

                    {
                        data: 'simb_val',
                        name: 'simb_val',
                        width: '1%',
                    },

                    {
                        data: 'totale',
                        name: 'totale',
                        width: '1%',
                    },


                ];




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",

                    data: window.dataset,


                    ajax: {
                        url: pointerVue.getUrlDomain("InvoiceWebClientiGetInvDetailsApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCustomer = window.curCustomer;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;



                            var valSearch = $("#myTable_filter input[type='search']").val();

                            var search = {
                                "value": $("#myTable_filter input[type='search']").val(),
                                "regex": false
                            };

                            if (valSearch.length > 0) {
                                valori.search = search;
                                valori.columns = window.columns;
                                console.log(window.columns);
                            }



                            window.valori = valori;
                            return valori;

                        }

                    },




                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;


                        }, 2000);





                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                        $('.p_quantita, .p_prezzo_unitario, .p_subtotale').number(true, 2, ',', '.');





                        pointerVue.updateTotalsGrid();


                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("InvoiceWebClientiGetInvDetailsApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                //                 $("#myTable_filter").prepend(`
                //     <div class="cont_buttons">

                //         <div class='wrap_buttons'><b>Dettagli</b>


                //         ` + filtri + `

                //         </div>

                //     </div>
                // `);




                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });


                $('body').on('click', '.selpdffatture', function () {

                    console.log(("EVENTO CLICK SELPDFFATTURE"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfFattura(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }

                });




                $('body').on('click', '.selpdfanticipi', function () {

                    console.log(("EVENTO CLICK SELPDFANTICIPI"));

                    var errore = "";

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });


                    if (tot == 0) {

                        errore = "Selezionare almeno una fattura";

                    }

                    if (errore == "") {

                        if (tot > 1) {

                            errore = "Selezionare solo una fattura";

                        }


                    }


                    if (errore == "") {

                        pointerVue.pdfAnticipo(ids);


                    }

                    if (errore != "") {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });



                    }






                });



                $('body').on('click', '.selscadenziario', function () {

                    console.log(("EVENTO CLICK SELSCADENZIARIO"));

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    var errore = "";

                    if (tot == 0) {
                        errore = "Selezionare almeno una elemento";
                    }

                    if (errore == "") {

                        if (tot > 1) {
                            errore = "Selezionare solo un elemento";
                        }

                    }



                    if (errore == "") {

                        window.$cookies.set("type_fatt_from", "Clienti", "9y");

                        router.push({
                            path: "/scadenziario/" + ids
                        });

                    }
                    else {

                        pointerVue.$swal({
                            icon: "error",
                            text: errore,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                });



                $('body').on('click', '.selexportgridpdf', function () {
                    console.log("EVENT SELEXPORTGRIDPDF");


                    pointerVue.exportGridToPdf();

                });


                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status_dett").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_dettagli_fattura tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status_dett").prop("checked", false);

                                $(this).removeClass("active-row");

                                //  pointerVue.updateCalculator(row_data.totale_pagare_calc, "+");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status_dett").prop("checked", true);

                                $(this).addClass("active-row");

                                //  pointerVue.updateCalculator(row_data.totale_pagare_calc, "-");


                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND val_status_dett: ", $(this).find(".val_status_dett"));

                            var find_check = $(this).find(".val_status_dett");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });


                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);



                    if (this.value == 2) {


                        router.push({
                            path: "/fattureFornitori"
                        });



                    }


                });



                $('body').on('click', '.val_status_dett', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW SEL: ", item.totale_pagare_calc);

                        window.id_par_sel = item.id;


                        //     pointerVue.updateCalculator(item.totale_pagare_calc, "+");


                    }

                    if (!checked) {

                        var item2 = window.table.row(v_row).data();

                        console.log("ITEM ROW DESEL: ", item2.totale_pagare_calc);

                        $(v_row).removeClass("active-row");


                        //  pointerVue.updateCalculator(item2.totale_pagare_calc, "-");



                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },



        setValute: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("VALUTE TMP: ", this.valuteTemp);

            var Valute = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.valuteTemp.length; i++) {

                    if (parseInt(this.valuteTemp[i].companyid) == parseInt(id_azienda)) {

                        Valute.push({
                            idinvoice_valute: this.valuteTemp[i].idinvoice_valute,
                            simbolo: this.valuteTemp[i].simbolo
                        });

                    }

                }


            }


            console.log("VALUTE SEL: ", Valute);

            this.valute = Valute;

        },





        setIVA: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.ivaTemp);

            var Iva = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.ivaTemp.length; i++) {

                    if (parseInt(this.ivaTemp[i].companyid) == parseInt(id_azienda)) {

                        Iva.push({
                            idinvoice_partita_iva: this.ivaTemp[i].idinvoice_partita_iva,
                            desc_valuta: this.ivaTemp[i].desc_valuta
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Iva);

            this.iva = Iva;

        },

        setBanche: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.bancheTemp);

            var Banche = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.bancheTemp.length; i++) {

                    if (parseInt(this.bancheTemp[i].companyid) == parseInt(id_azienda)) {

                        Banche.push({
                            idinvoice_banche: this.bancheTemp[i].idinvoice_banche,
                            nome: this.bancheTemp[i].nome
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Banche);

            this.banche = Banche;

        },

        setTipiPagamento: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.tipiPagamentoTemp);

            var TipiPagamento = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.tipiPagamentoTemp.length; i++) {

                    if (parseInt(this.tipiPagamentoTemp[i].companyid) == parseInt(id_azienda)) {

                        TipiPagamento.push({
                            idinvoice_tipo_pagamento: this.tipiPagamentoTemp[i].idinvoice_tipo_pagamento,
                            descrizione: this.tipiPagamentoTemp[i].descrizione
                        });

                    }

                }

            }


            console.log("UT ASS SEL: ", TipiPagamento);

            this.tipiPagamento = TipiPagamento;

        },


        setTrasporti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.trasportoTemp);

            var Trasporto = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.trasportoTemp.length; i++) {

                    if (parseInt(this.trasportoTemp[i].our_company) == parseInt(id_azienda)) {

                        Trasporto.push({
                            idwarehouse_trasportation_methods: this.trasportoTemp[i].idwarehouse_trasportation_methods,
                            description: this.trasportoTemp[i].description
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Trasporto);

            this.trasporto = Trasporto;

        },

        setFattureRiferimento: function (id_azienda, id_cliente) {

            console.log("AZI SEL 2: ", id_azienda);
            console.log("CLI SEL 2: ", id_cliente);

            console.log("UTENTI TMP: ", this.fattureRiferimentoTemp);

            var FattureRiferimento = [];

            if ((id_azienda != "") && (id_cliente != "")) {

                for (var i = 0; i < this.fattureRiferimentoTemp.length; i++) {

                    if ((parseInt(this.fattureRiferimentoTemp[i].companyid) == parseInt(id_azienda)) && (parseInt(this.fattureRiferimentoTemp[i].company) == parseInt(id_cliente))) {

                        FattureRiferimento.push({
                            idinvoice: this.fattureRiferimentoTemp[i].idinvoice,
                            numero: this.fattureRiferimentoTemp[i].numero
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", FattureRiferimento);

            this.fattureRiferimento = FattureRiferimento;

        },

        setOrdine: function (id_azienda, id_cliente) {

            console.log("AZI SEL 2: ", id_azienda);
            console.log("CLI SEL 2: ", id_cliente);

            console.log("UTENTI TMP: ", this.ordiniTemp);

            var Ordini = [];

            if ((id_azienda != "") && (id_cliente != "")) {

                for (var i = 0; i < this.ordiniTemp.length; i++) {

                    if ((parseInt(this.ordiniTemp[i].company) == parseInt(id_azienda)) && (parseInt(this.ordiniTemp[i].companyid) == parseInt(id_cliente))) {

                        Ordini.push({
                            idinvoice_ordini: this.ordiniTemp[i].idinvoice_ordini,
                            num: this.ordiniTemp[i].num
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", Ordini);

            this.ordini = Ordini;

        },

        setUM: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("UTENTI TMP: ", this.unitaDiMisuraTemp);

            var UnitaDiMisura = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.unitaDiMisuraTemp.length; i++) {

                    if (parseInt(this.unitaDiMisuraTemp[i].companyid) == parseInt(id_azienda)) {

                        UnitaDiMisura.push({
                            idinvoice_unita_di_misura: this.unitaDiMisuraTemp[i].idinvoice_unita_di_misura,
                            simbolo: this.unitaDiMisuraTemp[i].simbolo
                        });

                    }

                }


            }


            console.log("UT ASS SEL: ", UnitaDiMisura);

            this.unitaDiMisura = UnitaDiMisura;

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.sendmagic_company_sender.companyid = azienda.companyid;

        },




        setupButtons: async function () {

            console.log("SETUP BUTTONS");


            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: this.getUrlDomain("public/_lib/img/icon_save_sendmagic.png"),
                    title: "Save",
                    width: 30

                });


                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: this.getUrlDomain("public/_lib/img/back-sendmagic.png"),
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Back",
                    width: 35

                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },


        validate() {

            var errore = "";

            var that = this;

            console.log("COMPANY SENDER: ", that.sendmagic_company_sender);


            if (that.sendmagic_company_sender.companyid == "") {
                errore = "Company required";
            }

            if (errore == "") {
                if (that.sendmagic_company_sender.sender == "") {
                    errore = "Sender required";
                }
            }


            if (errore == "") {

                console.log("ok");

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },


        saveData: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.saveCompanySender(
                that.$route.params.id,
                that.sendmagic_company_sender
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveCompanySender", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Data saved successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/companySender"
                        });

                    }, 200);


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 4000
                    });


                }
            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



    }

})
</script>

<style>
.editCompanySender .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.w-container-edit-company-sender {
    width: 500px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.usersInGroup {
    font-weight: bold;
    font-size: 11px;
    margin-left: 10px;
    margin-top: 10px;
    min-height: 80px;
    max-height: 80px;
    overflow-y: auto;
}

.titleUsersInGroup {
    font-weight: bold;
    font-size: 11px;
    margin-left: 10px;
}


.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_sendmagic.png") !important;
    background-size: 34px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_sendmagic.png") !important;
    background-size: 32px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

#fldDaysLeft {
    margin-left: -10px;
}

.editCompanySender .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    margin-left: -10px;
}


.editCompanySender .cl-checkbox i {
    font-size: 13px !important;
}



.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemCliente {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.v-form {
    margin-top: 41px;
}

.mainTitleEditFattura {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 4;
    top: 65px;
    border: 1px solid black;
    background: white !important;
}

.editCompanySender .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editCompanySender .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.editCompanySender .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
    margin-top: 12px !important;
}

.editCompanySender ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

.cl-del-date-fattura {
    width: 15px;
    transform: translate(395px, -54px);
    cursor: pointer;
}

.cl-del-date-scad-fattura {
    width: 15px;
    transform: translate(395px, -54px);
    cursor: pointer;
}




.cont_dettagli_fattura .dataTable tbody tr,
.cont_dettagli_fattura .dataTable tbody tr td {
    vertical-align: middle !important;
    padding-top: 5px !important;
}

/* .cont_dettagli_fattura #myTable {
    margin-right: 3px;
    width: 1500px !important;
} */

.cont_dettagli_fattura #myTable {
    margin-right: 3px;
    width: 400px !important;
}

.cont_dettagli_fattura table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_dettagli_fattura table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-top: 5px !important;
    vertical-align: middle;
}

.cont_dettagli_fattura th {
    padding-left: 5px !important;
}

.cont_dettagli_fattura ion-datetime.dateBook {
    margin-left: 16px;
}


.cont_dettagli_fattura .table-responsive {
    padding: 0px;
    min-width: 466px;
    max-width: 466px;
}





.cont_dettagli_fattura #myTable_filter {
    position: relative;
    left: 0px;
    min-width: 100%;
    z-index: 2;
    background-color: white;
    display: none
}

.cont_dettagli_fattura div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}


.cont_dettagli_fattura #myTable_wrapper {
    min-width: initial;
    min-width: 466px !important;
    max-width: 466px !important;
    width: 466px !important;
    margin-left: 0px !important;
    min-height: 600px !important;
}


.cont_dettagli_fattura .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_dettagli_fattura .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_dettagli_fattura .cl_checkall_dett {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_dettagli_fattura .checkall_dett {
    width: 13px;
    height: 13px;
}

.cont_dettagli_fattura .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_dettagli_fattura .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
    width: 100% !important;
}

.cont_dettagli_fattura .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_dettagli_fattura .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 30px !important;
    height: 10px !important;
    margin-left: 22px !important;
}

.cont_dettagli_fattura .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_dettagli_fattura .green {
    background: green;
}

.cont_dettagli_fattura .yellow {
    background: #ecec06;
}

.cont_dettagli_fattura .red {
    background: red;
}

.cont_dettagli_fattura .lightblue {
    background: lightblue;
}

.cont_dettagli_fattura .orange {
    background: orange;
}

.cont_dettagli_fattura .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_dettagli_fattura .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_dettagli_fattura .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/change_invoiceweb2.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_dettagli_fattura .selcliente {
    max-width: 28px;
    min-height: 28px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_dettagli_fattura .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_dettagli_fattura .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_dettagli_fattura div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

.cont_dettagli_fattura .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.cont_dettagli_fattura .cl_valuta {
    font-weight: bold;
    text-align: center
}

.cont_dettagli_fattura .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_dettagli_fattura .col_footer_no_border {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
}

.cont_dettagli_fattura .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_dettagli_fattura .val_status_dett {
    width: 10px !important;
}

.cont_dettagli_fattura .selpdffatture {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selpdfanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .silinvfattcust {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .sendmailalertcli {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selexportgridpdf {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_dettagli_fattura .selcopiafatt {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_dettagli_fattura .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_dettagli_fattura .cl_checkall_dett {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_dettagli_fattura .checkall {
    width: 13px;
    height: 13px;
}

.cont_dettagli_fattura .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_dettagli_fattura .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}

.cont_dettagli_fattura .col_footer_right_no_border {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    text-align: right;
    padding-right: 6px;
}

.cont_dettagli_fattura input[type='search'] {
    visibility: hidden;
}

.cl_title_details {
    transform: translate(4px, 48px);
    font-weight: bold;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-company-sender {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }



    .cont_dettagli_fattura #myTable_filter {
        position: relative !important;
        left: 0px !important;
        min-width: 100% !important;
        z-index: 2 !important;
        background-color: white !important;
        display: none
    }

    .cont_dettagli_fattura div.dataTables_wrapper div.dataTables_filter input {
        /* margin-top:5px!important */
    }

    .cont_dettagli_fattura #myTable_wrapper {
        min-width: initial;
        min-width: 366px !important;
        max-width: 366px !important;
        width: 366px !important;
        margin-left: 0px !important;
        min-height: 600px !important;
    }

    .cont_dettagli_fattura .table-responsive {
        padding: 0px !important;
        min-width: 366px !important;
        max-width: 366px !important;
    }

    .mainTitleEditFattura {
        padding: 20px !important;
        position: fixed !important;
        min-width: 386px !important;
        z-index: 4 !important;
        top: 65px !important;
        border: 1px solid black !important;
        background: white !important;
        margin-top: -8px !important;
    }


    .cont_dettagli_fattura #myTable {
        margin-right: 3px;
        width: 366px !important;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
