<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center editProfileEmt"
        style="min-width:280px;">

        <v-form style="min-width:300px;" ref="form" v-model="valid" lazy-validation>

            <v-avatar color="primary" size="56">
                <span class="white--text text-h5">{{ initials }}</span>

            </v-avatar>
            <v-row class="text-center titlenewrow" style="">

                <span>{{ namesurname }}</span>
            </v-row>


            <v-text-field v-model="profile.username" label="Username" readonly></v-text-field>

            <v-text-field v-model="profile.mail" :rules="emailRules" label="E-Mail" required></v-text-field>

            <v-text-field v-model="profile.tel_cell" label="Phone Number" required></v-text-field>


            <v-switch v-model="profile.enable_notification" false-value="N" true-value="S"
                label="Notifications"></v-switch>


            <div class="padding" style="min-height:100px;"></div>
        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="manageClose()">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Dipendenti
                            <!-- <v-switch @change="changeGruppi" style="position: absolute;z-index: 1;left: 8px;top: -13px;" v-model="switch1" :label="` `"></v-switch> -->

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip, 0)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
import _ from 'lodash';

export default {

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {

        /*     let self = this;
            fetch('https://jsonplaceholder.typicode.com/posts')
              .then(response => response.json())
              .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);



        console.log("VALUE NOTIFICATION: ", window.valueNotification);



        this.$root.$children[0].checkLogout();

        this.isAgila = window.$cookies.get("is_agila");


        this.dipendenti = this.$root.$children[0].dipendenti;

        this.gruppi = this.$root.$children[0].gruppi;

        this.aziende = this.$root.$children[0].aziende;
        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        this.setupButtons();
        setTimeout(() => {
            this.syncProfile();
        }, 100);

        // setTimeout(() => {

        //     this.syncNotificationValue();

        // }, 600);






        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_aziende":
                    //this.admindialog = true ;
                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);
                    break;

                case "btn_add_tms":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;


                case "btn_delete_profile":

                    this.deleteAccountAction();

                    break;

                case "btn_change_psw":

                    router.push({
                        path: "/changepasswordprofile/"
                    });


                    break;

                case "btn_create_qr":

                    this.createQRUser();


                    break;




                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({

        valueNotification: "N",

        isAgila: 0,

        viewField: false,

        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        profile: { enable_notification: "N" },


        profileTemp: {},

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {


        createQRUser: async function () {

            var that = this;

            var ids = window.$cookies.get("user_id");

            // $(".val_status").each(function (index) {

            //     console.log(index);

            //     if ($(this).prop("checked")) {

            //         if (ids != "") {
            //             ids = ids + ",";
            //         }
            //         ids = ids + $(this).attr("data-id");
            //     }

            // });

            // console.log("IDS SEL:", ids);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.validateCreateQRCode(
                ids,
                v_token
            ).then(async (res) => {

                console.log("res from validateCreateQRCode", res);

                var response2 = await apiusers.createQRCodeSingle(
                    ids,
                    v_token
                ).then((res2) => {

                    console.log(response2);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from createQRCodeSingle", res2);

                    that.$swal({
                        icon: "success",
                        text: "QRCode Generato Con Successo",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    var file_path = res2.data.ValUrl;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);



                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },




        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        deleteAccountAction: function () {

            var that = this;

            console.log("PROFILE: ", that.profile);



            var spinner = $("#bspinner");
            var loginButton = $("#login-button");

            var API_URL = this.getUrlDomain("api/resetAccount");


            var loginpar = window.$cookies.get("username");

            var imgConfBtn = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_sendmagic.png") + "' />";
            var imgRemBtn = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_sendmagic.png") + "' />";



            //  var that = this;
            this.$swal.fire({
                title: "Remove Account",
                html: "Are you sure you want to remove your account ?",
                showCancelButton: true,
                confirmButtonText: imgConfBtn,
                cancelButtonText: imgRemBtn,
                customClass: {
                    cancelButton: 'order-1',
                    confirmButton: 'order-2',
                },

                /*  input: "text", */

                inputAttributes: {
                    autocapitalize: "off",
                },

                preConfirm: (username) => {
                    console.log(username);

                    // show spinner && disable button
                    spinner.removeClass("vhidden");
                    that.showSpinner = true;

                    loginButton.prop("disabled", true);

                    var body = {
                        loginpar: loginpar,
                        email: that.profile.mail,
                        method: "REMOVE_ACCOUNT",
                        context: window.context,
                    };

                    console.log(body);
                    // call the service

                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;


                    $.ajax({
                        type: "POST",
                        url: API_URL,
                        data: body,
                        success: function (resultData) {

                            that.showSpinner = false;
                            that.$root.$children[0].showProgress = false;

                            // hide spinner && enable button
                            spinner.addClass("vhidden");
                            that.showSpinner = false;

                            loginButton.prop("disabled", false);

                            if (resultData.Result == "OK") {
                                var message;

                                if (resultData.send_type == "SMS") {
                                    message = "Verify your SMS";
                                } else {
                                    message = "Check your E-Mail";
                                }

                                //open ok modal

                                that.$swal.fire({
                                    icon: "success",
                                    title: message,
                                    showConfirmButton: true,
                                    confirmButtonText: imgConfBtn,
                                });

                                that.$root.$children[0].logout();

                            } else {
                                var errorMessage;

                                switch (resultData.Result) {
                                    case "USER_NOT_FOUND":
                                        errorMessage = "User not founf";
                                        break;
                                    default:
                                        errorMessage = "User not founf";
                                }

                                that.$swal.fire({
                                    text: errorMessage,
                                    icon: "error",
                                    showConfirmButton: false,
                                });

                                that.showSpinner = false;
                            }
                        },
                        error: function () {
                            // hide spinner && enable login button
                            spinner.addClass("vhidden");
                            loginButton.prop("disabled", false);
                            that.showSpinner = false;
                            //  errorHandler(event);
                        },
                    });
                },
            });
        },


        changeDip: function () {

            /*   var token = window.$cookies.get("token");
              var system = window.$cookies.get("system");
              var user_id = window.$cookies.get("user_id");

              if (this.currDipendenteObj.resourceid !== undefined) {
                  //alert (  this.currDipendenteObj.resourceid  );
                  system = this.currDipendenteObj.v_res_id;
                  token = this.currDipendenteObj.v_user;
                  user_id = this.currDipendenteObj.v_user_id;

              } */

            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;

                }
            }

            this.$root.$children[0].curDipTop = selNameDip;

            // console.log(token, system, user_id);
            this.syncProfile();

        },
        manageClickDipendenti: function (dipendente) {
            this.sheetDipendenti = false;
            this.currDipendente = dipendente.resourceid;
            //  alert ( dipendente );
            // this.resetDay();
            this.changeDip();
            setTimeout(() => {
                // this.syncMonth();

            }, 100);
            /*   this.syncArrayDipendenti();
              console.log ("CurAzienda",this.currAzienda );
              console.log(this.dipendenti);

              this.sheetDipendenti = true ; */

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            this.currDipendente = "";
            this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            //console.log("Elenco aziende:", this.aziende);
            console.log("Elenco aziende:", aziende);

            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {
                this.sheetAziende = false;
                this.sheetDipendenti = true;
            }, 300);

        },

        manageClose: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;

        },

        setupButtons: async function () {

            setTimeout(() => {


                var pulsantis = [


                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_add_tms",
                        disabled: false,
                        image: this.getUrlDomain("public/_lib/img/icon_save_sendmagic.png"),
                        title: "Save",
                        width: 30

                    },


                ];


                pulsantis.push({
                    text: "Delete",
                    icon: "mdi-home-circle",
                    image: this.getUrlDomain("public/_lib/img/icon_delete_sendmagic.png"),
                    link: "/info",
                    id: "btn_delete_profile",
                    disabled: false,
                    title: "Delete",
                    width: 30

                });


                pulsantis.push({
                    text: "Delete",
                    icon: "mdi-home-circle",
                    image: this.getUrlDomain("public/_lib/img/change_password-sendmagic.png"),
                    link: "/info",
                    id: "btn_change_psw",
                    disabled: false,
                    title: "Change Password",
                    width: 23

                });








                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },



        saveProfile: async function () {

            console.log("PROFILE: ", this.profile);

            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");


            if (this.currDipendenteObj.resourceid !== undefined) {
                username = atob(this.currDipendenteObj.v_user);
                token_system = this.currDipendenteObj.way;

            }

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {

                response = await apiprofile.setProfileNew(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {

                console.log(response.data.Result);


                this.saveNotificationValue();



            } catch (error) {
                console.log(error);
            }

        },


        saveNotificationValue: async function () {

            console.log("PROFILE: ", this.profile);

            var username = window.$cookies.get("username");

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            try {

                response = await apiprofile.saveNotificationValue(
                    username,
                    this.profile.enable_notification
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {

                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });



            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },


        saveProfileWitoutRes: async function () {

            console.log("PROFILE: ", this.profile);

            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");


            if (this.currDipendenteObj.resourceid !== undefined) {
                username = atob(this.currDipendenteObj.v_user);
                token_system = this.currDipendenteObj.way;

            }

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {

                response = await apiprofile.setProfileWithoutRes(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {

                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });



            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },


        syncNotificationValue: async function () {




            var username = window.$cookies.get("username");


            //   console.log ( system );
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var result;
            try {

                result = await apiprofile.getNotificationValue(
                    username
                ).then((response) => {

                    this.showSpinner = false;
                    this.$root.$children[0].showProgress = false;

                    try {


                        console.log("result from getNotificationValue: ", response);

                        this.profile.enable_notification = response.data.Result;


                    } catch (error) {
                        console.log(error);
                        alert(error.message);
                    }

                    console.log(result);

                });
            } catch (error) {
                alert(error.message);

                this.showSpinner = false;
                this.$root.$children[0].showProgress = false;
            }


        },



        syncProfile: async function () {



            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");

            console.log("TOKEN SYSTEM", token_system);

            console.log("oki", this.currDipendenteObj);

            if (this.currDipendenteObj.resourceid !== undefined) {
                token_system = this.currDipendenteObj.way;
                username = atob(this.currDipendenteObj.v_user);

            }


            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var result;
            try {
                result = await apiprofile.getProfile(
                    username,
                    token_system
                ).then((response) => {
                    this.showSpinner = false;
                    this.$root.$children[0].showProgress = false;

                    try {


                        this.profile = response.data.Result;
                        this.profile.username = username;
                        this.email = response.data.Result.mail;


                        this.cellulare = response.data.Result.tel_cell;

                        if (this.profile.tel_cell == "null") {
                            this.profile.tel_cell = "";
                        }

                        this.profile.enable_notification = window.valueNotification;


                        this.namesurname = response.data.Result.name + " " + response.data.Result.surname;
                        this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0);

                    } catch (error) {
                        console.log(error);
                        alert(error.message);
                    }

                    console.log(result);

                });
            } catch (error) {
                alert(error.message);

                this.showSpinner = false;
                this.$root.$children[0].showProgress = false;
            }




        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
  console.log('a phone number was clicked');
}    <template>
    <div class="text-center">
        <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
            <v-sheet class="text-center" height="100vh">
                <div class="headerSheetAziende" fixed style="position:fixed;">
                    Aziende
                    <v-btn class='closeSheet' icon @click="manageClose()">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <v-toolbar>
                        <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>
                    </v-toolbar>
                </div>
                <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                    close
                </v-btn> -->
                <v-divider></v-divider>
                <div class="spacer" style="min-height:72px;"></div>
                <v-flex xs12 sm12>
                    <v-card>

                        <v-list-item class="text-left" v-for="(tile,key) in filteredAziende" :key="key" @click="manageClickAziende(tile)">
                            <v-icon aria-hidden="false">mdi-domain</v-icon>
                            <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                            <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                        </v-list-item>
                    </v-card>
                </v-flex>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

,
*/
        reply(par) {
            console.log(par);
        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Please verify the data",
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }


                });
            } else {

                console.log("Profile: ", this.profile);


                if (this.isAgila == 0) {

                    this.saveProfile();
                }

                if (this.isAgila == 1) {

                    this.saveProfileWitoutRes();
                }




            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;

}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.editProfileEmt .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.editProfileEmt .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.editProfileEmt ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}
</style>