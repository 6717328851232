import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIBackgroundimage {

    getUrlDomainApi(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + "api/" + nome_metodo;

    }


    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async saveSettingsSendMagic(settingsemtool) {

        let formData = new FormData();

        for (var key in settingsemtool) {
            formData.append(key, settingsemtool[key]);
        }

        return axios.post(this.getUrlDomain("saveSettingsSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async getFilesizeSendMagic(nomefile) {

        let formData = new FormData();

        formData.append("nomefile", nomefile);

        return axios.post(this.getUrlDomain("getFilesizeSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIBackgroundimage({
    url: "http://emtool.local/public/api/smartbook/"
})
