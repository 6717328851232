import axios from 'axios'

class APISendMagic {

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;

  }


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }
  

  async sendSMS(user, prefix_telefono, numero_telefono, testo_messaggio) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prefix_telefono", prefix_telefono);
    formData.append("numero_telefono", numero_telefono);
    formData.append("testo_messaggio", testo_messaggio);

    return axios.post(this.getUrlDomain("sendSMSSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getCounters(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getCountersSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendNotification(user, prefix_telefono, numero_telefono, testo_messaggio) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prefix_telefono", prefix_telefono);
    formData.append("numero_telefono", numero_telefono);
    formData.append("testo_messaggio", testo_messaggio);

    return axios.post(this.getUrlDomain("sendNotificationSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createGroup(ids, name_group, v_companyid) {

    let formData = new FormData();

    formData.append("ids", ids);
    formData.append("name_group", name_group);
    formData.append("v_companyid", v_companyid);

    return axios.post(this.getUrlDomain("createGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertGroup(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);


    return axios.post(this.getUrlDomain("initInserGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveGroup(gruppo, id) {

    let formData = new FormData();

    formData.append("v_id", id);
  
    for (var key in gruppo) {
      formData.append(key, gruppo[key]);
    }


    return axios.post(this.getUrlDomain("saveGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditGroup(v_id, prodotto, username) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("prodotto", prodotto);
    formData.append("username", username);


    return axios.post(this.getUrlDomain("initEditGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteGroup(v_id, username) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("username", username);

    return axios.post(this.getUrlDomain("deleteGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveUsersToGroup(v_id_group, id_users) {

    let formData = new FormData();

    formData.append("v_id_group", v_id_group);
    formData.append("id_users", id_users);

    return axios.post(this.getUrlDomain("saveUsersToGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendSMSToGroup(user, testo_messaggio, id_gruppo, id_azienda) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("testo_messaggio", testo_messaggio);
    formData.append("id_gruppo", id_gruppo);
    formData.append("id_azienda", id_azienda);

    return axios.post(this.getUrlDomain("sendSMSToGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async sendNotificationToGroup(user, testo_messaggio, id_gruppo) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("testo_messaggio", testo_messaggio);
    formData.append("id_gruppo", id_gruppo);

    return axios.post(this.getUrlDomain("sendNotificationToGroupSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async checkTokenNotification(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("checkTokenNotification"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initInsertConfSMS(username, prodotto) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("initInsertConfSMS"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveConfSMS(v_id, configurazione) {

    let formData = new FormData();

    formData.append("v_id", v_id);
  
    for (var key in configurazione) {
      formData.append(key, configurazione[key]);
    }


    return axios.post(this.getUrlDomain("saveConfSMSSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditConfSMS(username, prodotto, v_id) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("prodotto", prodotto);
    formData.append("v_id", v_id);


    return axios.post(this.getUrlDomain("initEditConfSMS"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteConfSMS(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteConfSMS"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async initInsertConfNotification(username, prodotto) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("initInsertConfNotification"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveConfNotification(v_id, configurazione) {

    let formData = new FormData();

    formData.append("v_id", v_id);
  
    for (var key in configurazione) {
      formData.append(key, configurazione[key]);
    }


    return axios.post(this.getUrlDomain("saveConfNotifSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initEditConfNotification(username, prodotto, v_id) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("prodotto", prodotto);
    formData.append("v_id", v_id);


    return axios.post(this.getUrlDomain("initEditConfNotification"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteConfNotification(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteConfNotification"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initInsertCompanySender(username, prodotto) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("initInsertCompanySender"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveCompanySender(v_id, company_sender) {

    let formData = new FormData();

    formData.append("v_id", v_id);
  
    for (var key in company_sender) {
      formData.append(key, company_sender[key]);
    }


    return axios.post(this.getUrlDomain("saveCompanySenderSendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditCompanySender(username, prodotto, v_id) {

    let formData = new FormData();

    formData.append("username", username);
    formData.append("prodotto", prodotto);
    formData.append("v_id", v_id);


    return axios.post(this.getUrlDomain("initEditCompanySender"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteCompanySender(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteCompanySender"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getImageBackGroundCompany(companyid) {

    let formData = new FormData();

    formData.append("companyid", companyid);

    return axios.post(this.getUrlDomain("getImageBackGroundCompanySendMagic"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APISendMagic({
  url: "http://emtool.local/public/api/smartbook/"
})
