<template>
    <v-container class="w-container-send-sms v-overflow send_sms_sendmagic"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                    background-color: white;
                    color: black;
                    font-weight: bold;
                    padding: 4px;
                    font-size: 10px;
                    min-width:100vw;
                    border-color:#005da8">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_typesend" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Type Send </ion-label>

                            <ion-select id="idtypesend" name="idtypesend" class='idtypesend' cancel-Text="Close"
                                done-Text="" :value="selTypeSend" interface="action-sheet">

                                <ion-select-option v-for="item in typeSend" :key="item.id" :value='item.id'> {{
                        item.description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="viewRowCompany">

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Company </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="selAzienda" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{
                        item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="viewRowGroup">

                <v-col cols="12" md="12">

                    <div id="cont_group" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Group </ion-label>

                            <ion-select id="groupid" name="groupid" class='groupid' cancel-Text="Close" done-Text=""
                                :value="selGruppo" interface="action-sheet">

                                <ion-select-option v-for="item in gruppi" :key="item.id" :value='item.id'> {{
                        item.nome }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="viewRowUsersInGroup">

                <v-col cols="12" md="12">

                    <div class="titleUsersInGroup">Users In Group</div>
                    <div id="divUsersInGroup" class="usersInGroup"></div>


                </v-col>



            </v-row>


            <v-row v-if="viewRowPhoneNumber">

                <v-col cols="6" md="6">

                    <div id="cont_prefisso" style="cursor:pointer">
                        <ion-item>

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Prefix </ion-label>

                            <ion-select id="prefisso" name="prefisso" class='prefisso' cancel-Text="Close" done-Text=""
                                :value="prefixNumeroTelefono" interface="action-sheet">

                                <ion-select-option v-for="item in prefissi" :key="item.value" :value='item.value'> {{
                        item.description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

                <v-col cols="6" md="6">


                    <v-text-field v-model="numeroTelefono" label="Number">
                    </v-text-field>

                </v-col>



            </v-row>

            <v-row v-if="viewRowTextMessage">

                <v-col cols="12" md="12">

                    <v-textarea v-model="testoMessaggio" label="Text Message">
                    </v-textarea>

                </v-col>



            </v-row>



        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Company
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_sendmagic.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                    id="fldTextSearchAzi"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-sendmagic.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <div class="contpopuperrorsendgroup" style="display:none">

            <template>
                <modal name="popupErrorSendGroup" :clickToClose="false" :width="371" :height="411">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;display: flex; justify-content: center;">

                            <v-img alt="" src="@/assets/error.png" max-width="60" max-height="60" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="font-weight: bold;
    font-size: 11px;
    margin-left: 10px;">

                            The following messages were not sent:

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: 0px!important;">

                            <div style="display: flex;margin-left: 10px;">

                                <div style="font-size: 11px;font-weight: bold;width:100px">User</div>
                                <div style="font-size: 11px;font-weight: bold;width:100px">Phone Number</div>
                                <div style="font-size: 11px;font-weight: bold;width:143px">Error</div>

                            </div>

                            <v-list style="overflow-y:auto;max-height: 199px;min-height: 199px;">

                                <v-list-tile v-for="el in erroriSend" :key="el.ErrPhoneNumber">

                                    <div style="display: flex;margin-left: 10px;">

                                        <div style="font-size: 9px;font-weight: bold;width:100px">{{
                        el.ErrNameUserDestination }}</div>
                                        <div style="font-size: 9px;font-weight: bold;width:100px">{{ el.ErrPhoneNumber
                                            }}</div>
                                        <div style="font-size: 9px;font-weight: bold;width:143px">{{ el.ErrDetails }}
                                        </div>

                                    </div>



                                </v-list-tile>


                            </v-list>


                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_sendmagic.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelPopupError" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";
import apisendmagic from "../utils/sendmagic/apisendmagic";
import router from ".././router";

import {
    bus
} from "../main";

import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        var pointerVue = this;

        // default send basic
        this.selTypeSend = "B";
        this.prefixNumeroTelefono = "+39";
        this.viewRowPhoneNumber = true;
        this.viewRowTextMessage = true;

        this.setupButtons();

        setTimeout(() => {

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm_sms":

                    this.validate();

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });

            $('body').on('ionChange', '.idtypesend', function (event) {
                console.log(event);

                pointerVue.selTypeSend = this.value;


                pointerVue.viewRowCompany = false;
                pointerVue.viewRowPhoneNumber = false;
                pointerVue.viewRowTextMessage = false;
                pointerVue.viewRowGroup = false;
                pointerVue.viewRowUsersInGroup = false;

                pointerVue.prefixNumeroTelefono = "+39";
                pointerVue.numeroTelefono = "";
                pointerVue.testoMessaggio = "";
                pointerVue.selAzienda = null;
                pointerVue.selGruppo = null;
                pointerVue.utentiNelGruppo = "";

                if (pointerVue.selTypeSend == "B") // basic
                {

                    pointerVue.viewRowPhoneNumber = true;
                    pointerVue.viewRowTextMessage = true;


                }

                if (pointerVue.selTypeSend == "G") // group
                {

                    pointerVue.viewRowCompany = true;
                    pointerVue.viewRowGroup = true;
                    pointerVue.viewRowTextMessage = true;
                    pointerVue.viewRowUsersInGroup = true;


                }

            });


            $('body').on('ionChange', '.groupid', function (event) {
                console.log(event);

                pointerVue.selGruppo = this.value;

                pointerVue.setUtenti(this.value);


            });




        });



    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        erroriSend: [],

        utentiNelGruppo: "",

        usersTemp: [],

        viewRowUsersInGroup: false,

        viewRowGroup: false,

        selGruppo: null,

        gruppiTemp: [],
        gruppi: [],

        prefissi: [{ value: "+39", description: "+39" }],

        numeroTelefono: "",
        testoMessaggio: "",
        prefixNumeroTelefono: "",

        viewRowTextMessage: false,


        viewRowPhoneNumber: false,


        viewRowCompany: false,

        typeSend: [{ id: "B", description: "Basic" }, { id: "G", description: "Group" }],


        selTypeSend: "",


        titolo: "SMS",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        select: null,

        checkbox: false,
    }),


    methods: {


        btnCancelPopupError: function () {

            this.$modal.hide('popupErrorSendGroup');

            setTimeout(() => {

                router.push({
                    path: "/logSMS"
                });

            }, 100);


        },



        sendSMS: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.sendSMS(
                v_token,
                that.prefixNumeroTelefono,
                that.numeroTelefono,
                that.testoMessaggio
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendSMS", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "SMS sent successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/logSMS"
                        });

                    }, 2000);


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/logSMS"
                        });

                    }, 3000);


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },



        sendSMSToGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.sendSMSToGroup(
                v_token,
                that.testoMessaggio,
                that.selGruppo,
                that.selAzienda
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendSMSToGroup", res);


                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "SMS sent successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/logSMS"
                        });

                    }, 2000);

                }
                else {


                    for (var x = 0; x < res.data.ErrDetails.length; x++) {

                        that.erroriSend.push({
                            ErrDetails: res.data.ErrDetails[x].ErrDetails,
                            ErrNameUserDestination: res.data.ErrDetails[x].ErrNameUserDestination,
                            ErrPhoneNumber: res.data.ErrDetails[x].ErrPhoneNumber,
                            Result: res.data.ErrDetails[x].Result
                        });

                    }


                    console.log("ERRORI: ", that.erroriSend);


                    // console.log("ok");

                    setTimeout(() => {

                        $(".contpopuperrorsendgroup").show();
                        that.$modal.show('popupErrorSendGroup');

                    }, 200);





                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },


        checkPhoneNumber: function (v_number) {

            var result;

            var validRegex = /^\d+$/;

            if (v_number.toString().match(validRegex)) {
                result = true;
            }
            else {
                result = false;

            }

            return result;

        },

        setUtenti: function (id_gruppo) {

            console.log("AZI SEL 2: ", id_gruppo);

            console.log("DIP TMP: ", this.usersTemp);

            var UtentiNelGruppo = "";

            for (var i = 0; i < this.usersTemp.length; i++) {

                if (parseInt(this.usersTemp[i].id_group) == parseInt(id_gruppo)) {

                    if (UtentiNelGruppo != "") {
                        UtentiNelGruppo = UtentiNelGruppo + "<br/>";
                    }
                    UtentiNelGruppo = UtentiNelGruppo + this.usersTemp[i].full_name;

                }

            }

            this.utentiNelGruppo = UtentiNelGruppo;

            $("#divUsersInGroup").html(UtentiNelGruppo);

        },




        setGruppi: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.gruppiTemp);

            var Gruppi = [];

            for (var i = 0; i < this.gruppiTemp.length; i++) {

                if (parseInt(this.gruppiTemp[i].companyid) == parseInt(id_azienda)) {

                    Gruppi.push({
                        id: this.gruppiTemp[i].id,
                        nome: this.gruppiTemp[i].nome,
                        companyid: this.gruppiTemp[i].companyid
                    });

                }

            }

            console.log("DIP SEL: ", Gruppi);

            this.gruppi = Gruppi;

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },


        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            this.setGruppi(azienda.companyid);


        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: this.getUrlDomain("public/_lib/img/btn_confirm_sendmagic.png"),
                        link: "/timesheet",
                        id: "btn_confirm_sms",
                        disabled: false,
                        title: "Confirm",
                        width: 30
                    },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Conferma",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_confirm_sms",
                        disabled: false,
                        image: this.getUrlDomain("public/_lib/img/btn_confirm_sendmagic.png"),
                        title: "Confirm",
                        width: 30

                    },




                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";



            if (that.selTypeSend == "") {

                errore = "Type Send Required";
            }


            if (that.selTypeSend == "B") // send basic
            {

                if (errore == "") {

                    if (that.prefixNumeroTelefono == "") {

                        errore = "Prefix Required";
                    }
                }


                if (errore == "") {

                    if (that.numeroTelefono == "") {

                        errore = "Number Required";
                    }
                }


                if (errore == "") {

                    if (that.testoMessaggio == "") {

                        errore = "Text Message Required";
                    }
                }


                if (errore == "") {

                    if (!that.checkPhoneNumber(that.numeroTelefono)) {

                        errore = "Number Not Valid";
                    }
                }

            }

            if (that.selTypeSend == "G") // send to group
            {

                if (errore == "") {

                    if (that.selAzienda == null) {

                        errore = "Company Required";
                    }
                }

                if (errore == "") {

                    if (that.selGruppo == null) {

                        errore = "Group Required";
                    }
                }

                if (errore == "") {

                    if (that.utentiNelGruppo == "") {

                        errore = "No users present in the group";
                    }
                }

                if (errore == "") {

                    if (that.testoMessaggio == "") {

                        errore = "Text Message Required";
                    }
                }


            }


            if (errore == "") {

                if (that.selTypeSend == "B") {

                    that.sendSMS();

                }

                if (that.selTypeSend == "G") {

                    console.log("ok");

                    that.sendSMSToGroup();

                }



            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }


        },



        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getAziende(
                v_token,
                "sendmagic"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziende", res);

                this.aziende = res.data.company;

                this.gruppiTemp = res.data.groups;

                this.usersTemp = res.data.users;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.usersInGroup {
    font-weight: bold;
    font-size: 11px;
    margin-left: 10px;
    margin-top: 10px;
    min-height: 80px;
    max-height: 80px;
    overflow-y: auto;
}

.titleUsersInGroup {
    font-weight: bold;
    font-size: 11px;
    margin-left: 10px;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-send-sms {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.send_sms_sendmagic .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}


.send_sms_sendmagic .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.send_sms_sendmagic .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
    margin-top: 12px !important;
}

.send_sms_sendmagic ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.w-container-send-sms .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}




@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-send-sms {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
