<template>
    <v-container class="w-container-filter-image-bkg filter_bkg_image"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">
    </v-container>
</template>

<script>

import apisendmagic from "../utils/sendmagic/apisendmagic";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/


        this.$root.$children[0].checkLogout();


        var id_cmp = window.$cookies.get("sel_id_cmp");


        console.log("ID AZIENDA: ", id_cmp);


        this.getImageBackGroundCompany(id_cmp);

    },



    data: () => ({
        titolo: "Immagine Sfondo",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        // nucleiRules

        select: null,

        //gruppi: window.$cookies.get("gen_settings").gruppi,

        checkbox: false,
    }),

    methods: {


        getImageBackGroundCompany: async function (id_azienda) {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisendmagic.getImageBackGroundCompany(
                id_azienda
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getImageBackGroundCompany", res);

                window.$cookies.set("settSendmagic", JSON.stringify(res.data.Result), "9y");

                setTimeout(() => {

                    router.push({
                        path: "/immagineSfondo"
                    });

                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getCompany(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCompany", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-filter-image-bkg {
    width: 500px;
    padding: 0px;
    overflow-y: hidden;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.filter_bkg_image .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.filter_bkg_image .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.filter_bkg_image .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.filter_bkg_image ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.w-container-filter-image-bkg .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}




@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-filter-image-bkg {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
