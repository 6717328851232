<template>
    <v-container id="conf_settings_alertmsg" class="w-container editDisease" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row v-if="fieldInsert">

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="malattia.periodo_dal" label="Data Inizio" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="malattia.periodo_dal" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>


                </v-col>

            </v-row>

            <v-row v-if="fieldInsert">

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="malattia.periodo_al" label="Data Fine" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="malattia.periodo_al" no-title @input="showPicker2 = false"
                            locale="it"></v-date-picker>
                    </v-menu>


                </v-col>

            </v-row>




            <v-row v-if="!fieldInsert">

                <v-col cols="12" md="12">

                    <v-text-field id="periodo_dal" v-model="malattia.periodo_dal" label="Data Inizio" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="!fieldInsert">

                <v-col cols="12" md="12">

                    <v-text-field id="periodo_al" v-model="malattia.periodo_al" label="Data Fine" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="numero_protocollo" v-model="malattia.numero_protocollo" label="Numero Protocollo">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea id="note" v-model="malattia.note" label="Note"></v-textarea>

                </v-col>

            </v-row>

        </v-form>

    </v-container>
</template>

<script>

//import apisettings from "../utils/settings/apisettings";
import apidisease from "../utils/disease/apidisease";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        this.$root.$children[0].checkLogout();

        /*eslint-disable no-undef*/

        if (this.$route.params.id == 0) {

            this.titolo = "Inserimento Malattia";

        }


        this.setupButtons();

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Malattia";

            this.fieldInsert = false;

            setTimeout(() => {
                this.syncDisease();
            }, 100);

        }



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/diseaseHistory"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({




        fieldInsert: true,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        malattia: { periodo_dal: "", periodo_al: "" },

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Modifica Malattia",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30,

                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                }

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },


        addZero: function (number) {
            return (number < 10 ? '0' : '') + number;
        },

        validate() {

            var that = this;

            var errore = "";

            if (this.$route.params.id == 0) {

                if (that.malattia.periodo_dal == "") {
                    errore = "Specificare valore Data Inizio";
                }

                if (errore == "") {

                    if (that.malattia.periodo_al == "") {
                        errore = "Specificare valore Data Fine";
                    }

                }

                var dt_start = "";
                var dt_end = "";

                if (errore == "") {

                    dt_start = new Date(that.malattia.periodo_dal).getTime();
                    dt_end = new Date(that.malattia.periodo_al).getTime();

                    if (dt_start > dt_end) {

                        errore = "Data Fine deve essere maggiore o uguale a Data Inizio";


                    }

                }

                if (errore == "") {

                    var current_date = new Date();
                    var formattedDate = current_date.getFullYear() + '-' + that.addZero((current_date.getMonth() + 1)) + '-' + that.addZero(current_date.getDate());

                    console.log("CURRENT DATE: ", formattedDate);

                    current_date = new Date(formattedDate).getTime();



                    if ((dt_start < current_date) || (dt_end < current_date)) {


                        errore = "Data Inizio e Data Fine devono essere maggiori o uguali della Data Corrente";



                    }


                }


            }



            if (errore == "") {

                console.log("MALATTIA: ", that.malattia);

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }





        },

        saveData: function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var startDate = that.malattia.periodo_dal;
            var endDate = that.malattia.periodo_al;
            var certificato = that.malattia.numero_protocollo;
            var notes = that.malattia.note;

            if (that.$route.params.id == 0) {


                this.$swal
                    .fire({
                        title: "Sei sicuro?",
                        html: "Vuoi inserire la malattia ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                        cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                        customClass: {
                            cancelButton: 'order-1',
                            confirmButton: 'order-2',
                        }
                    })
                    .then((result) => {
                        if (result.value == true) {
                            //console.log (true);

                            //HolidayRequest.holidaysRequest(username, password, action, startDate, endDate, timeStart, timeEnd, hours, notes);
                            // var start = this.range.start;
                            // var monthStart = (start.getMonth() + 1).toString().padStart(2, "0");
                            // var dayStart = (start.getDate()).toString().padStart(2, "0");

                            // var end = this.range.end;
                            // var monthEnd = (end.getMonth() + 1).toString().padStart(2, "0");
                            // var dayEnd = (end.getDate()).toString().padStart(2, "0");

                            // var startDate = start.getFullYear() + "-" + monthStart + "-" + dayStart;

                            // var endDate = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;

                            //this.timeStart
                            //this.timeEnd

                            // var notes = $("#notes").val();
                            // var certificato = this.certificato;

                            // console.log(startDate, endDate, notes);

                            // var token = window.$cookies.get("token");

                            /*   var username = window.$cookies.get("username");
                              var token_system = window.$cookies.get("token_system"); */
                            //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";

                            //var API_URL = "https://app.emtool.eu/api/insertDisease";

                            var API_URL = this.getUrlDomainApi("insertDisease");


                            // if (this.currDipendenteObj.resourceid !== undefined) {
                            //     console.log("currDipendenteObj", this.currDipendenteObj);
                            //     /*   username = atob(this.currDipendenteObj.v_user);
                            //       token_system = this.currDipendenteObj.way; */
                            //     token = this.currDipendenteObj.v_user;

                            // }

                            // var that = this;
                            // that.showSpinner = true;
                            // console.log("this.causali", this.causali);
                            // alert (  this.causale );

                            /*   $val_user = $request->v_user;
                                        $val_db_conf = $request->v_db_conf;
                                        $data_inizio = $request->data_inizio;
                                        $data_fine = $request->data_fine;
                                        $protocollo = $request->protocollo;
                                        $note = $request->note;
                                        $val_db_conf = $request->v_db_conf;
                                        $attachment = $request->attachment;
                                         */
                            /* eslint-disable no-unreachable */
                            /*    return; */


                            that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;


                            $.ajax({
                                type: "POST",
                                url: API_URL,
                                data: {
                                    "v_user": v_token,
                                    'data_inizio': startDate,
                                    'data_fine': endDate,
                                    'protocollo': certificato,
                                    'note': notes

                                },
                                success: function (resultData) {

                                    that.showSpinner = false;
                                    that.$root.$children[0].showProgress = false;

                                    console.log("resultData", resultData, that);

                                    that.$swal({
                                        icon: "success",
                                        text: "La richiesta è stata salvata correttamente",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });


                                    setTimeout(() => {

                                        router.push({
                                            path: "/diseaseHistory"
                                        });

                                    }, 2000);

                                },
                                error: function (errorData) {
                                    console.log(errorData);
                                    var msg = errorData.responseJSON.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg,
                                        showConfirmButton: false,
                                        timer: 3000
                                    });
                                    that.showSpinner = false;

                                }
                            });


                        }
                    });








            }

            if (that.$route.params.id > 0) {

                // var startDate = that.malattia.periodo_dal;
                // var endDate = that.malattia.periodo_al;
                // var certificato = that.malattia.numero_protocollo;
                // var notes = that.malattia.note;
                var id_disease = that.$route.params.id;

                console.log("START DATE:", startDate);
                console.log("END DATE:", endDate);
                console.log("CERTIFICATO:", certificato);
                console.log("NOTE:", notes);
                console.log("ID:", id_disease);
                console.log("TOKEN:", v_token);

                var API_URL = this.getUrlDomainApi("updateDisease");


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                $.ajax({
                    type: "POST",
                    url: API_URL,
                    data: {
                        "v_user": v_token,
                        'data_inizio': startDate,
                        'data_fine': endDate,
                        'protocollo': certificato,
                        'id_malattia': id_disease,
                        'note': notes

                    },
                    success: function (resultData) {

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;

                        console.log("resultData", resultData, that);

                        that.$swal({
                            icon: "success",
                            text: "La richiesta è stata salvata correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });


                        setTimeout(() => {

                            router.push({
                                path: "/diseaseHistory"
                            });

                        }, 2000);


                    },
                    error: function (errorData) {
                        console.log(errorData);
                        var msg = errorData.responseJSON.Error;
                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 3000
                        });

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;
                    }
                });


            }






        },

        syncDisease: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidisease.getEditDisease(
                v_token,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getEditDisease", res);

                that.malattia = res.data.disease;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editDisease {
    overflow-y: hidden !important;
}

.editDisease .v-input {
    font-size: 1em !important;
}

.editDisease .v-select {
    font-size: 1em !important;
}

.editDisease .v-label {
    font-size: 1em !important;
}


#conf_settings_alertmsg .v-label {
    font-size: 1em;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
